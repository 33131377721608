/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../../Components/UI/Header";
import Sidebar from "../../Components/UI/Sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetManageStates,
  setFundraisingDropdown,
} from "../../Store/Slices/Sidebar/SidebarSlice";
import TransactionsComponent from "../../Components/Sidebar/TransactionsComponent";

// get user from LS
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

const RecordTransactions = ({
  sidebarToggled,
  setSidebarToggled,
  quickLinksDropdown,
  setQuickLinksDropdown,
}) => {
  // state
  const [user] = useState(getUser());

  // navigate
  const navigate = useNavigate();

  // redirect user to login if no user found
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, []);

  // dispatch
  const dispatch = useDispatch();

  // automatically open fundraising dropdown if user reaches the page directly
  useEffect(() => {
    dispatch(setFundraisingDropdown(true));
  }, []);

  // reset manage states
  useEffect(() => {
    dispatch(resetManageStates());
  }, []);

  return (
    <>
      {user && (
        <div className={sidebarToggled ? "dashboard" : "dashboard toggled"}>
          <Header
            sidebarToggled={sidebarToggled}
            setSidebarToggled={setSidebarToggled}
            quickLinksDropdown={quickLinksDropdown}
            setQuickLinksDropdown={setQuickLinksDropdown}
          />
          <div className="content-wrapper">
            <Sidebar sidebarToggled={sidebarToggled} />
            <TransactionsComponent token={user.token} />
          </div>
        </div>
      )}
    </>
  );
};

export default RecordTransactions;
