import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../Store/Slices/Sidebar/Users/CrmUsersSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";
import Select from "react-select";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const AddUserModal = ({ token, setAddUserModal }) => {
  // redux state
  const { addUserLoading, addUserData } = useSelector(
    (state) => state.crmUsers
  );

  // close modal
  const handleCloseModal = () => {
    if (!addUserLoading) {
      setAddUserModal(false);
    }
  };

  // form states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lang, setLang] = useState("en");
  const [status, setStatus] = useState(0);

  // lang options
  const langOptions = [
    { value: "en", label: "English" },
    { value: "fr", label: "French" },
  ];

  // status change
  const handleStatusChange = () => {
    setStatus((prevStatus) => (prevStatus === 1 ? 0 : 1));
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // add user
  const handleAddUserSubmit = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      requestType: "new",
      firstName,
      lastName,
      email,
      accountPassword: password,
      language: lang,
      status,
    };
    dispatch(addUser(singleObj)).then((res) => {
      if (!res.payload.axiosError) {
        setAddUserModal(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Add User</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form className="update-media-form" onSubmit={handleAddUserSubmit}>
              {/* first name and last name */}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    placeholder="First Name"
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    placeholder="Last Name"
                  />
                </MDBCol>
              </MDBRow>

              {/* email and password */}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label>Email</label>
                  <input
                    type="email"
                    className="edit-media-inputs"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Email"
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label>Password</label>
                  <input
                    type="password"
                    className="edit-media-inputs"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                  />
                </MDBCol>
              </MDBRow>

              {/* lang and status */}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <h6>Language</h6>
                  <Select
                    options={langOptions}
                    styles={styles}
                    value={langOptions.find((option) => option.value === lang)}
                    onChange={(option) => setLang(option.value)}
                    isDisabled
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <h6>Status</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={status === 1}
                      onChange={handleStatusChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </MDBCol>
              </MDBRow>

              {addUserData && addUserData.apiError && (
                <div className="error-msg">{addUserData.apiError}</div>
              )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={addUserLoading}
                >
                  {addUserLoading ? (
                    <MetroSpinner
                      loading={addUserLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddUserModal;
