/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import DatePicker from "react-datepicker";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { ic_close } from "react-icons-kit/md/";
import { useDispatch, useSelector } from "react-redux";
import {
  addCampaign,
  createModalMount,
} from "../../Store/Slices/Sidebar/Campaigns/CampaignSlice";
import LazyLoad from "react-lazy-load";

function AddCampaignModal({ token }) {
  // backshadow variants
  const backVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  // modal variants
  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const [step, setStep] = useState("campaign-details");
  const [isIconSelect, setIsIconSelect] = useState(null);
  const [applyToAll, setApplyToAll] = useState(false);

  // form state
  const [name, setName] = useState("");
  const [iconTag, setIconTag] = useState(0);
  const [description, setDescription] = useState("");

  // api hitting states
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // datepicker display states
  const [displayStartDate, setDisplayStartDate] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(null);

  const [status, setStatus] = useState(0);
  const [allowRecurringModification, setAllowRecurringModification] =
    useState(1);
  const [targetAmount, setTargetAmount] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [issueTaxReceipt, setIssueTaxReceipt] = useState(0);
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [fees, setFees] = useState(0.0);
  const [taxReceiptRatio, setTaxReceiptRatio] = useState(1.0);
  const [nodes, setNodes] = useState([]);
  const [donationCampaign, setDonationCampaign] = useState(1);
  const [hidden, setHidden] = useState(false);
  const [enableQuantity, setEnableQuantity] = useState(false);

  const initialState = () => {
    setStep("campaign-details");
    setIsIconSelect(null);
    setApplyToAll(false);
    setName("");
    setIconTag(0);
    setDescription("");
    setStartDate(null);
    setEndDate(null);
    setDisplayStartDate(null);
    setDisplayEndDate(null);
    setStatus(0);
    setAllowRecurringModification(1);
    setTargetAmount(0);
    setSortOrder("");
    setIssueTaxReceipt(0);
    setMinimumAmount(0);
    setFees(0.0);
    setTaxReceiptRatio(1.0);
    setNodes([]);
    setDonationCampaign(1);
    setHidden(false);
    setEnableQuantity(false);
  };

  const changeIconFunction = (id, imgSrc) => {
    setIconTag(id);
    setIsIconSelect(imgSrc);
    setStep("campaign-details");
  };

  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    iconsData,
    iconsLoader,
    iconsError,
    nodeData,
    nodeLoader,
    nodeError,
  } = useSelector((state) => state.getIconsAndNodes); // reducer data

  const { createLoader, createError, createSuccessMessage } = useSelector(
    (state) => state.campaign
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(createModalMount());
      if (nodeData && nodeData.length > 0) {
        const updatedNodes = nodeData.map((v) => ({
          organizationDefinedName: v.organizationDefinedName,
          nodeTag: v.tagNumber,
          status: false,
        }));
        setNodes(updatedNodes);
      }
    } else {
      // modal close state is cleared
      initialState();
    }
  }, [isOpen]);

  const handlesetStep = () => {
    setStep("icon-step");
  };

  // add success
  useEffect(() => {
    if (createSuccessMessage) {
      setIsOpen(false);
      toast.success(createSuccessMessage, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(createModalMount());
    }
  }, [createSuccessMessage]);

  // toggle modal
  const toggleModal = () => {
    if (!createLoader) {
      setIsOpen(!isOpen);
    }
  };

  // setstate function
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  // number input sort order
  const handleNumberInput = (setState) => (event) => {
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    } else if (newValue > 10) {
      newValue = 10;
    }
    setState(newValue);
  };

  // number not negative
  const handleNumberNotNegative = (setState) => (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setState(newValue);
  };

  // handle start and end date change
  const handleDate = (setState) => (value) => {
    setState(value);
  };

  // Convert unformatted display date into formatted string for API
  useEffect(() => {
    if (displayStartDate) {
      const year = displayStartDate.getFullYear();
      const month = String(displayStartDate.getMonth() + 1).padStart(2, "0");
      const day = String(displayStartDate.getDate()).padStart(2, "0");
      const hours = String(displayStartDate.getHours()).padStart(2, "0");
      const minutes = String(displayStartDate.getMinutes()).padStart(2, "0");
      const seconds = String(displayStartDate.getSeconds()).padStart(2, "0");

      const formatted = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      setStartDate(formatted);
    } else {
      setStartDate(null);
    }
  }, [displayStartDate]);

  // Convert unformatted display date into formatted string for API
  useEffect(() => {
    if (displayEndDate) {
      const year = displayEndDate.getFullYear();
      const month = String(displayEndDate.getMonth() + 1).padStart(2, "0");
      const day = String(displayEndDate.getDate()).padStart(2, "0");
      const hours = String(displayEndDate.getHours()).padStart(2, "0");
      const minutes = String(displayEndDate.getMinutes()).padStart(2, "0");
      const seconds = String(displayEndDate.getSeconds()).padStart(2, "0");

      const formatted = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      setEndDate(formatted);
    } else {
      setEndDate(null);
    }
  }, [displayEndDate]);

  // handle nodes function
  const handleNodes = (tag) => (event) => {
    const isChecked = event.target.checked;
    const updatedNodes = nodes.map((node) =>
      node.nodeTag === tag ? { ...node, status: isChecked } : node
    );
    // Check if all nodes have status set to true
    const allNodesChecked = updatedNodes.every((node) => node.status);

    // Set applyToAll based on the result
    setApplyToAll(allNodesChecked);
    setNodes(updatedNodes);
  };

  // apply to all
  const handleApplyToAll = (isChecked) => {
    setApplyToAll(isChecked);
    const updatedNodes = nodes.map((node) => ({
      ...node,
      status: isChecked, // Set status to true if isChecked is true, false otherwise
    }));

    // Update the state variable nodes with the new array
    setNodes(updatedNodes);
  };

  // handle tax receipt ratio
  const handleTaxReceiptRatio = (event) => {
    let newValue = parseFloat(event.target.value);
    // Ensure value is within the specified range
    newValue = Math.min(Math.max(newValue, 0), 1);
    // Round to two decimal places
    newValue = Math.round(newValue * 100) / 100;
    if (isNaN(newValue)) {
      newValue = "";
    }
    setTaxReceiptRatio(newValue);
  };

  // submit function
  const handleSubmit = (event) => {
    event.preventDefault();
    let obj = {
      name,
      description,
      startDate,
      endDate,
      status,
      sortOrder,
      iconTag,
      issueTaxReceipt,
      nodes,
      donationCampaign,
      taxReceiptRatio,
      allowRecurringModification,
      hidden,
      enableQuantity,
    };
    if (targetAmount === "") {
      obj.targetAmount = 0;
    } else {
      obj.targetAmount = parseFloat(targetAmount);
    }
    if (minimumAmount === "") {
      obj.minimumAmount = 0;
    } else {
      obj.minimumAmount = parseFloat(minimumAmount);
    }
    if (fees === "") {
      obj.fees = 0.0;
    } else {
      obj.fees = parseFloat(fees);
    }

    dispatch(addCampaign({ token, obj }));
  };

  return (
    <>
      <button type="button" onClick={toggleModal} className="action-buttons">
        Add Campaign
      </button>
      <div className="addCampaignComponent">
        {isOpen && (
          <AnimatePresence>
            <motion.div
              className="modalOverlay"
              variants={backVariants}
              initial="hidden"
              animate="visible"
            >
              <motion.div
                className="modalContent"
                variants={modalVariants}
                initial="hidden"
                animate="visible"
              >
                <div className="modalHeader">
                  <h5 className="mb-0 fs-14">Create Campaign</h5>
                  <button onClick={toggleModal} className="close">
                    <Icon icon={ic_close} size={20} />
                  </button>
                </div>
                {step === "campaign-details" ? (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="modalBody">
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <div className="form-group mb-3">
                              <label className="form-label">Name</label>
                              <input
                                onChange={handleChange(setName)}
                                value={name}
                                type="text"
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="form-group mb-3 position-relative">
                              <label className="form-label me-2">Icon</label>
                              {isIconSelect && (
                                <img
                                  alt="icon"
                                  src={isIconSelect}
                                  style={{ width: 30, height: 30 }}
                                />
                              )}
                              <button
                                onClick={handlesetStep}
                                type="button"
                                className="browseIcon"
                                disabled={createLoader}
                              >
                                Browse
                              </button>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 mb-3">
                            <label className="form-label">
                              Description (Max Characters: 1000)
                            </label>
                            <textarea
                              maxLength={1000}
                              onChange={handleChange(setDescription)}
                              value={description}
                              className="form-control"
                              rows="5"
                              required
                            ></textarea>
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <div className="form-group">
                              <label className="form-label">
                                Start Date and Time
                              </label>
                              <DatePicker
                                selected={displayStartDate}
                                onChange={handleDate(setDisplayStartDate)}
                                className="form-control"
                                showTimeSelect
                                timeIntervals={5}
                                timeFormat="HH:mm"
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeCaption="Time"
                                placeholderText="Start Date and Time"
                                isClearable
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <div className="form-group">
                              <label className="form-label">
                                End Date and Time
                              </label>
                              <DatePicker
                                selected={displayEndDate}
                                onChange={handleDate(setDisplayEndDate)}
                                className="form-control"
                                showTimeSelect
                                timeIntervals={5}
                                timeFormat="HH:mm"
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeCaption="Time"
                                placeholderText="End Date and Time"
                                isClearable
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 mb-3">
                            <div className="row">
                              <div className="col-sm-6 mb-3">
                                <label className="form-label">
                                  Target Amount
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={targetAmount}
                                    onChange={handleNumberNotNegative(
                                      setTargetAmount
                                    )}
                                    inputMode="decimal"
                                    step="100"
                                    title=""
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6 mb-3">
                                <label className="form-label">
                                  Minimum Amount
                                </label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={minimumAmount}
                                    onChange={handleNumberNotNegative(
                                      setMinimumAmount
                                    )}
                                    inputMode="decimal"
                                    step="0.01"
                                    title=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 mb-3">
                            <div className="row">
                              <div className="col-12 col-sm-4 mb-3">
                                <label className="form-label">Fee</label>
                                <div className="input-group">
                                  <span className="input-group-text">%</span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={fees}
                                    onChange={handleNumberNotNegative(setFees)}
                                    inputMode="decimal"
                                    step="0.01"
                                    title=""
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-sm-4 mb-3">
                                <label className="form-label">Sort Order</label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    value={sortOrder}
                                    onChange={handleNumberInput(setSortOrder)}
                                    className="form-control"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-sm-4 mb-3">
                                <label className="form-label">
                                  Tax Receipt Ratio
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    value={taxReceiptRatio}
                                    onChange={handleTaxReceiptRatio}
                                    className="form-control"
                                    required
                                    inputMode="decimal"
                                    title=""
                                    step={0.01}
                                    min={0}
                                    max={1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-2 mb-3">
                            <label className="form-label">
                              Issue Tax Receipt
                            </label>
                            <div className="d-flex mt-1">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setIssueTaxReceipt(e.target.checked ? 1 : 0)
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 mb-3">
                            <label className="form-label">
                              Allow Recurring Changes
                            </label>
                            <div className="d-flex mt-1">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={allowRecurringModification}
                                  onChange={(e) =>
                                    setAllowRecurringModification(
                                      e.target.checked ? 1 : 0
                                    )
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 mb-3">
                            <label className="form-label">Enable Quantity</label>
                            <div className="d-flex mt-1">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setEnableQuantity(e.target.checked ? true : false)
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 mb-3">
                            <label className="form-label">
                              Donation Campaign
                            </label>
                            <div className="d-flex mt-1">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setDonationCampaign(
                                      e.target.checked ? 1 : 0
                                    )
                                  }
                                  checked={donationCampaign}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 mb-3">
                            <label className="form-label">Status</label>
                            <div className="d-flex mt-1">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setStatus(e.target.checked ? 1 : 0)
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 mb-3">
                            <label className="form-label">Hidden</label>
                            <div className="d-flex mt-1">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setHidden(e.target.checked ? true : false)
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <label className="form-label">Devices</label>
                              <Tippy content="Apply To All">
                                <label className="switch switch-apply">
                                  <input
                                    type="checkbox"
                                    checked={applyToAll}
                                    onChange={(e) =>
                                      handleApplyToAll(e.target.checked)
                                    }
                                  />
                                  <span className="slider round slider-apply"></span>
                                </label>
                              </Tippy>
                            </div>

                            <div className="row mx-0 border nodesContainer">
                              {/* error handle */}
                              {nodeError && (
                                <div className="col-12 border p-2">
                                  <div className="error-msg m-0">
                                    {nodeError}
                                  </div>
                                </div>
                              )}
                              {/* data handle */}
                              {!nodeLoader && nodes.length > 0
                                ? nodes.map((v, i) => (
                                    <div
                                      key={v.nodeTag}
                                      className="col-12 col-md-4 py-2"
                                    >
                                      <p className="mb-2">
                                        {v.organizationDefinedName}
                                      </p>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={v.status}
                                          onChange={handleNodes(v.nodeTag)}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  ))
                                : // Loader handle
                                  nodeLoader && (
                                    <div className="col-12 border p-2 text-center">
                                      <MetroSpinner
                                        size={30}
                                        color="#007C16"
                                        loading={nodeLoader}
                                      />
                                    </div>
                                  )}
                            </div>
                          </div>
                        </div>
                        <div className="border-top py-3">
                          {createError && (
                            <div className="error-msg mb-3 mt-0">
                              {createError}
                            </div>
                          )}
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              onClick={toggleModal}
                              className="modalCancelBtn me-2"
                            >
                              CANCEL
                            </button>
                            <button
                              type="submit"
                              className="modalSubmitBtn"
                              disabled={createLoader}
                            >
                              {createLoader ? (
                                <div className="d-flex justify-content-center">
                                  <MetroSpinner
                                    size={20}
                                    color="#fff"
                                    loading={createLoader}
                                  />
                                </div>
                              ) : (
                                "ADD"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  <div className="modalBody">
                    <h6 className="select-icon">
                      Select an icon for your campaign
                    </h6>
                    <>
                      {iconsError && (
                        <div className="error-msg mb-3 mt-0">{iconsError}</div>
                      )}
                      <div className="d-flex flex-wrap gap-10 icons-group">
                        {!iconsLoader && iconsData.length > 0 ? (
                          iconsData.map((v, i) => (
                            <label
                              key={v.tagNumber}
                              className={
                                iconTag === v.tagNumber ? "selected" : ""
                              }
                            >
                              <input
                                className="d-none"
                                type="radio"
                                name="iconsgroupt"
                                value={v.tagNumber}
                                checked={iconTag === v.tagNumber}
                                onChange={() =>
                                  changeIconFunction(v.tagNumber, v.filename)
                                }
                              />
                              <LazyLoad offset={50}>
                                <img
                                  className="radio-icon"
                                  src={v.filename}
                                  alt={v.tage}
                                />
                              </LazyLoad>
                            </label>
                          ))
                        ) : (
                          <div className="m-auto py-4">
                            <MetroSpinner
                              size={30}
                              color="#007C16"
                              loading={iconsLoader}
                            />
                          </div>
                        )}
                      </div>

                      <div
                        className="d-flex justify-content-end"
                        style={{
                          borderTop: "1px solid #e4e4e4",
                          paddingTop: 10 + "px",
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => setStep("campaign-details")}
                          className="modalCancelBtn me-2"
                        >
                          Back
                        </button>
                      </div>
                    </>
                  </div>
                )}
              </motion.div>
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </>
  );
}

export default AddCampaignModal;
