/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MetroSpinner } from "react-spinners-kit";
import DatePicker from "react-datepicker";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { ic_close } from "react-icons-kit/md/";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCampaign,
  updateModalMount,
} from "../../Store/Slices/Sidebar/Campaigns/CampaignSlice";
import LazyLoad from "react-lazy-load";

function UpdateCampaignModal({ token, open, setOpen, data }) {
  // backshadow variants
  const backVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  // modal variants
  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const dispatch = useDispatch();

  const { iconsData, iconsLoader, iconsError } = useSelector(
    (state) => state.getIconsAndNodes
  );

  const { updateLoader, updateError } = useSelector((state) => state.campaign);

  const [step, setStep] = useState("campaign-details");
  const [applyToAll, setApplyToAll] = useState(false);

  const toggleModal = () => {
    if (!updateLoader) {
      setOpen(!open);
      if (!open) {
        data = {};
        setStep("campaign-details");
        setApplyToAll(false);
      }
    }
  };

  // display states
  const [displayStartDate, setDisplayStartDate] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(null);
  // api states
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // form state
  const [name, setName] = useState(data.name);
  const [iconTag, setIconTag] = useState(data.icon.tagNumber);
  const [description, setDescription] = useState(data.description);
  const [status, setStatus] = useState(data.status ? 1 : 0);
  const [allowRecurringModification, setAllowRecurringModification] = useState(
    data.allowRecurringModification ? 1 : 0
  );
  const [targetAmount, setTargetAmount] = useState(data.targetAmount || 0);
  const [minimumAmount, setMinimumAmount] = useState(data.minimumAmount || 0);
  const [fees, setFees] = useState(data.fees || 0.0);
  const [taxReceiptRatio, setTaxReceiptRatio] = useState(
    data.taxReceiptRatio || 1
  );
  const [sortOrder, setSortOrder] = useState(data.sortOrder);
  const [issueTaxReceipt, setIssueTaxReceipt] = useState(
    data.issueTaxReceipt ? 1 : 0
  );
  const [nodes, setNodes] = useState(data.nodes);
  const [donationCampaign, setDonationCampaign] = useState(
    data.donationCampaign ? 1 : 0
  );
  const [hidden, setHidden] = useState(
    data.hidden ? true : false
  );
  const [enableQuantity, setEnableQuantity] = useState(
    data.enableQuantity ? true : false
  );

  useEffect(() => {
    // Check if all nodes have status set to true
    const allNodesChecked = data.nodes.every((node) => node.status);

    // Set applyToAll based on the result
    setApplyToAll(allNodesChecked);
  }, []);

  useEffect(() => {
    if (open) {
      dispatch(updateModalMount());
      // if modal is show then update date and set format like a date, hour & time.

      setStartDateFormat(data.startDate);
      setStartDate(data.startDate);

      setEndDateFormat(data.endDate);
      setEndDate(data.endDate);
    }
  }, [open]);

  // conerting the formatted date in to unformatted for datepicker to display
  const setStartDateFormat = (inputDate) => {
    const date = new Date(inputDate);
    setDisplayStartDate(date);
  };
  const setEndDateFormat = (inputDate) => {
    const date = new Date(inputDate);
    setDisplayEndDate(date);
  };

  // convert the unformatted api response into the formatted for edit api
  useEffect(() => {
    if (displayStartDate) {
      const year = displayStartDate.getFullYear();
      const month = String(displayStartDate.getMonth() + 1).padStart(2, "0");
      const day = String(displayStartDate.getDate()).padStart(2, "0");
      const hours = String(displayStartDate.getHours()).padStart(2, "0");
      const minutes = String(displayStartDate.getMinutes()).padStart(2, "0");
      const seconds = String(displayStartDate.getSeconds()).padStart(2, "0");

      const formatted = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      setStartDate(formatted);
    } else {
      setStartDate(null);
    }
  }, [displayStartDate]);

  // convert the unformatted api response into the formatted for edit api
  useEffect(() => {
    if (displayEndDate) {
      const year = displayEndDate.getFullYear();
      const month = String(displayEndDate.getMonth() + 1).padStart(2, "0");
      const day = String(displayEndDate.getDate()).padStart(2, "0");
      const hours = String(displayEndDate.getHours()).padStart(2, "0");
      const minutes = String(displayEndDate.getMinutes()).padStart(2, "0");
      const seconds = String(displayEndDate.getSeconds()).padStart(2, "0");

      const formatted = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      setEndDate(formatted);
    } else {
      setEndDate(null);
    }
  }, [displayEndDate]);

  // setstate function
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  // handle Number sort order
  const handleNumberInput = (setState) => (event) => {
    let newValue = parseInt(event.target.value, 10);
    if (newValue < 1) {
      newValue = 1;
    } else if (newValue > 10) {
      newValue = 10;
    }
    setState(newValue);
  };

  // handle number not negative
  const handleNumberNotNegative = (setState) => (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setState(newValue);
  };

  // handle date
  const handleDate = (setState) => (value) => {
    setState(value);
  };

  // handle nodes function
  const handleNodesChange = (tag) => (event) => {
    const isChecked = event.target.checked;
    const updatedNodes = nodes.map((node) =>
      node.nodeTag === tag ? { ...node, status: isChecked } : node
    );
    // Check if all nodes have status set to true
    const allNodesChecked = updatedNodes.every((node) => node.status);

    // Set applyToAll based on the result
    setApplyToAll(allNodesChecked);
    setNodes(updatedNodes);
  };

  // apply to all
  const handleApplyToAll = (isChecked) => {
    setApplyToAll(isChecked);
    const updatedNodes = nodes.map((node) => ({
      ...node,
      status: isChecked, // Set status to true if isChecked is true, false otherwise
    }));

    // Update the state variable nodes with the new array
    setNodes(updatedNodes);
  };

  // handle tax receipt ratio
  const handleTaxReceiptRatio = (event) => {
    let newValue = parseFloat(event.target.value);
    // Ensure value is within the specified range
    newValue = Math.min(Math.max(newValue, 0), 1);
    // Round to two decimal places
    newValue = Math.round(newValue * 100) / 100;
    if (isNaN(newValue)) {
      newValue = "";
    }
    setTaxReceiptRatio(newValue);
  };

  // submit function
  const handleSubmit = (event) => {
    event.preventDefault();
    let id = data.tagNumber;
    let obj = {
      name,
      description,
      startDate,
      endDate,
      status,
      targetAmount,
      minimumAmount,
      sortOrder,
      iconTag,
      issueTaxReceipt,
      nodes,
      donationCampaign,
      taxReceiptRatio,
      allowRecurringModification,
      hidden,
      enableQuantity
    };
    if (targetAmount === "") {
      obj.targetAmount = 0;
    } else {
      obj.targetAmount = parseFloat(targetAmount);
    }
    if (minimumAmount === "") {
      obj.minimumAmount = 0;
    } else {
      obj.minimumAmount = parseFloat(minimumAmount);
    }
    if (fees === "") {
      obj.fees = 0.0;
    } else {
      obj.fees = parseFloat(fees);
    }
    dispatch(updateCampaign({ id, token, obj }));
  };

  const changeIconFunction = (id) => {
    setIconTag(id);
    setStep("campaign-details");
  };

  return (
    <div className="updateCampaignComponent">
      {open && (
        <AnimatePresence>
          <motion.div
            className="modalOverlay"
            variants={backVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div
              className="modalContent"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
            >
              <div className="modalHeader">
                <h5 className="mb-0 fs-14">Update Campaign</h5>
                <button onClick={toggleModal} className="close">
                  <Icon icon={ic_close} size={20} />
                </button>
              </div>
              {step === "campaign-details" ? (
                <form onSubmit={handleSubmit}>
                  <div className="modalBody">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">
                        <div className="form-group mb-3">
                          <label className="form-label">Name</label>
                          <input
                            onChange={handleChange(setName)}
                            value={name}
                            type="text"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-group mb-3 position-relative">
                          <label className="form-label me-2">Icon</label>
                          {iconTag ? (
                            <img
                              src={
                                iconsData.find(
                                  (state) => iconTag === state.tagNumber
                                ).filename
                              }
                              style={{ width: 30, height: 30 }}
                              alt="icon"
                            />
                          ) : null}
                          <button
                            onClick={() => setStep("icon-step")}
                            type="button"
                            className="browseIcon"
                            disabled={updateLoader}
                          >
                            Browse
                          </button>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <label className="form-label">
                          Description (Max Characters: 1000)
                        </label>
                        <textarea
                          maxLength={1000}
                          onChange={handleChange(setDescription)}
                          value={description}
                          className="form-control"
                          rows="5"
                          required
                        ></textarea>
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <div className="form-group">
                          <label className="form-label">
                            Start Date and Time
                          </label>
                          <DatePicker
                            selected={displayStartDate}
                            onChange={handleDate(setDisplayStartDate)}
                            className="form-control"
                            showTimeSelect
                            timeIntervals={5}
                            timeFormat="HH:mm"
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeCaption="Time"
                            placeholderText="Start Date and Time"
                            isClearable
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <div className="form-group">
                          <label className="form-label">
                            End Date and Time
                          </label>
                          <DatePicker
                            selected={displayEndDate}
                            onChange={handleDate(setDisplayEndDate)}
                            className="form-control"
                            showTimeSelect
                            timeIntervals={5}
                            timeFormat="HH:mm"
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeCaption="Time"
                            placeholderText="End Date and Time"
                            isClearable
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <label className="form-label">Target Amount</label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <input
                                type="number"
                                className="form-control"
                                value={targetAmount}
                                onChange={handleNumberNotNegative(
                                  setTargetAmount
                                )}
                                inputMode="decimal"
                                step="100"
                                title=""
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <label className="form-label">Minimum Amount</label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <input
                                type="number"
                                className="form-control"
                                value={minimumAmount}
                                onChange={handleNumberNotNegative(
                                  setMinimumAmount
                                )}
                                inputMode="decimal"
                                step="0.01"
                                title=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <div className="row">
                          <div className="col-12 col-sm-4 mb-3">
                            <label className="form-label">Fee</label>
                            <div className="input-group">
                              <span className="input-group-text">%</span>
                              <input
                                type="number"
                                className="form-control"
                                value={fees}
                                onChange={handleNumberNotNegative(setFees)}
                                inputMode="decimal"
                                step="0.01"
                                title=""
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4 mb-3">
                            <label className="form-label">Sort Order</label>
                            <div className="input-group">
                              <input
                                onChange={handleNumberInput(setSortOrder)}
                                value={sortOrder}
                                type="number"
                                className="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-4 mb-3">
                            <label className="form-label">
                              Tax Receipt Ratio
                            </label>
                            <div className="input-group">
                              <input
                                type="number"
                                value={taxReceiptRatio}
                                onChange={handleTaxReceiptRatio}
                                className="form-control"
                                required
                                inputMode="decimal"
                                title=""
                                step={0.01}
                                min={0}
                                max={1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-2 mb-3">
                        <label className="form-label">Issue Tax Receipt</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setIssueTaxReceipt(e.target.checked ? 1 : 0)
                              }
                              checked={issueTaxReceipt}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 mb-3">
                        <label className="form-label">
                          Allow Recurring Changes
                        </label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={allowRecurringModification}
                              onChange={(e) =>
                                setAllowRecurringModification(
                                  e.target.checked ? 1 : 0
                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 mb-3">
                        <label className="form-label">Enable Quantity</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setEnableQuantity(e.target.checked ? true : false)
                              }
                              checked={enableQuantity}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 mb-3">
                        <label className="form-label">Donation Campaign</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setDonationCampaign(e.target.checked ? 1 : 0)
                              }
                              checked={donationCampaign}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 mb-3">
                        <label className="form-label">Status</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setStatus(e.target.checked ? 1 : 0)
                              }
                              checked={status}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 mb-3">
                        <label className="form-label">Hidden</label>
                        <div className="d-flex mt-1">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setHidden(e.target.checked ? true : false)
                              }
                              checked={hidden}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label className="form-label">Devices</label>
                          <Tippy content="Apply To All">
                            <label className="switch switch-apply">
                              <input
                                type="checkbox"
                                checked={applyToAll}
                                onChange={(e) =>
                                  handleApplyToAll(e.target.checked)
                                }
                              />
                              <span className="slider round slider-apply"></span>
                            </label>
                          </Tippy>
                        </div>
                        <div className="row mx-0 border nodesContainer">
                          {nodes.length > 0 ? (
                            nodes.map((v, i) => (
                              <div
                                key={v.nodeTag}
                                className="col-12 col-md-4 py-2"
                              >
                                <p className="mb-2">
                                  {v.organizationDefinedName}
                                </p>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={v.status}
                                    onChange={handleNodesChange(v.nodeTag)}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            ))
                          ) : (
                            <div className="p-2 text-center">
                              No Device Found
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="border-top py-3">
                      {updateError && (
                        <div className="error-msg mb-3 mt-0">{updateError}</div>
                      )}
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          onClick={toggleModal}
                          className="modalCancelBtn me-2"
                        >
                          CANCEL
                        </button>
                        <button
                          type="submit"
                          className="modalSubmitBtn"
                          disabled={updateLoader}
                        >
                          {updateLoader ? (
                            <div className="d-flex justify-content-center">
                              <MetroSpinner
                                size={20}
                                color="#fff"
                                loading={updateLoader}
                              />
                            </div>
                          ) : (
                            "UPDATE"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <>
                  <div className="modalBody">
                    <h6 className="select-icon">
                      Select an icon for your campaign
                    </h6>
                    {iconsError && (
                      <div className="error-msg mb-3 mt-0">{iconsError}</div>
                    )}
                    <div className="d-flex flex-wrap gap-10 icons-group">
                      {!iconsLoader && iconsData.length > 0 ? (
                        iconsData.map((v, i) => (
                          <label
                            key={v.tagNumber}
                            className={
                              iconTag === v.tagNumber ? "selected" : ""
                            }
                          >
                            <input
                              className="d-none"
                              type="radio"
                              name="iconsgroupt"
                              value={v.tagNumber}
                              checked={iconTag === v.tagNumber}
                              onChange={() => changeIconFunction(v.tagNumber)}
                            />
                            <LazyLoad offset={50}>
                              <img
                                className="radio-icon"
                                src={v.filename}
                                alt={v.tage}
                              />
                            </LazyLoad>
                          </label>
                        ))
                      ) : (
                        <div className="m-auto py-4">
                          <MetroSpinner
                            size={30}
                            color="#007C16"
                            loading={iconsLoader}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{
                      borderTop: "1px solid #e4e4e4",
                      paddingTop: 10 + "px",
                      paddingBottom: 10 + "px",
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => setStep("campaign-details")}
                      className="modalCancelBtn me-2"
                    >
                      Back
                    </button>
                  </div>
                </>
              )}
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default UpdateCampaignModal;
