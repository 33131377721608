import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteWallet,
  getWallets,
} from "../../../../Store/Slices/Wallet/WalletSlice";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const DeleteWalletPopup = ({
  token,
  email,
  deleteWalletObj,
  setDeleteWalletPopup,
}) => {
  // redux state
  const { deleteWalletLoading, deleteWalletData } = useSelector(
    (state) => state.wallet
  );

  // close modal
  const handleCloseModal = () => {
    if (!deleteWalletLoading) {
      setDeleteWalletPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // delete wallet
  const handleDeleteWallet = () => {
    dispatch(
      deleteWallet({ token, cardId: deleteWalletObj.cardId, email })
    ).then((res) => {
      if (!res.payload.axiosError) {
        setDeleteWalletPopup(false);
        const { successMsg } = res.payload;
        // get wallets
        dispatch(
          getWallets({
            token,
            email,
          })
        ).then((res) => {
          if (res.payload !== undefined) {
            toast.success(`${successMsg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (
            res.payload === undefined &&
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Delete Wallet - {deleteWalletObj.number}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#DC3545" }}>
                Are you sure you want to delete this card?
              </p>
              <p style={{ color: "#6a6c6f" }}>You cannot undo this action.</p>
            </div>
            {deleteWalletData &&
            deleteWalletData.apiError &&
            deleteWalletData.apiError !== undefined &&
            deleteWalletData.cardId === deleteWalletObj.cardId ? (
              <div className="error-msg">{deleteWalletData.apiError}</div>
            ) : (
              <>
                {deleteWalletData &&
                  deleteWalletData.apiError === undefined &&
                  deleteWalletData.axiosError &&
                  deleteWalletData.cardId === deleteWalletObj.cardId && (
                    <div className="error-msg">
                      {deleteWalletData.axiosError}
                    </div>
                  )}
              </>
            )}
            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="submit delete"
                disabled={deleteWalletLoading}
                onClick={handleDeleteWallet}
              >
                {deleteWalletLoading ? (
                  <MetroSpinner
                    loading={deleteWalletLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  "DELETE"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DeleteWalletPopup;
