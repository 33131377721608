/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { userPlus } from "react-icons-kit/feather/userPlus";
import { search } from "react-icons-kit/feather/search";
import { info } from "react-icons-kit/feather/info";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import toast from "react-hot-toast";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import ReactDatePicker from "react-datepicker";
import chequeSample from "../../Images/cheque_sample.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDonorsByEmail } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import {
  addECheckTransaction,
  clearAddETransferModalStates,
  clearBankingDetailsStates,
  getBankingDetails,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import AddDonorModal from "../Donors/AddDonorModal";

// custom text field
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// get organization for currency symbol
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const AddECheckTransactionPopup = ({
  token,
  setNewECheckTransactionPopup,
  countries,
  usaStates,
  provinces,
  campaignOptions,
  frequencyOptions,
  instituteOptions,
}) => {
  // state
  const [selectedOrganization] = useState(getOrganization()); // for currency symbol

  // redux state
  const {
    addETransactionLoading,
    addETransactionData,
    bankingDetailsLoading,
    bankingDetailsData,
  } = useSelector((state) => state.eCheck);

  // close modal
  const handleCloseModal = () => {
    if (!addETransactionLoading) {
      setNewECheckTransactionPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // step
  const [addDonorStep, setAddDonorStep] = useState(false);

  // account holder name
  const [accountHolderName, setAccountHolderName] = useState("");

  // email
  const [transactionEmail, setTransactionEmail] = useState("");

  // timer state
  const [timer, setTimer] = useState(null);

  // suggestions state
  const [suggestions, setSuggestions] = useState([]);

  // handle transaction email change event
  const handleTransactionEmail = (value) => {
    setTransactionEmail(value);
    // clear any existing timer
    if (timer) {
      clearTimeout(timer);
    }
    // create a new timer of 1 second
    const newTimer = setTimeout(() => {
      if (value.length > 3 && !browseStep) {
        dispatch(
          getDonorsByEmail({
            token,
            email: value,
          })
        ).then((res) => {
          if (res.payload?.data?.length > 0) {
            setSuggestions(res.payload.data);
          } else if (
            !res.payload ||
            !res.payload.data ||
            res.payload?.data?.length < 1
          ) {
            setSuggestions([]);
          } else if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      } else {
        setSuggestions([]);
      }
    }, 1000);
    setTimer(newTimer);
  };

  // campaign
  const [selectedCampaign, setSelectedCampaign] = useState({
    value: "",
    label: "",
    minimumAmount: "",
  });

  // amount
  const [amount, setAmount] = useState("");

  // amount change
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/[^0-9.]/g, "");
    const parts = newValue.split(".");
    if (parts.length > 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // frequency and institute
  const [selectedFrequency, setSelectedFrequency] = useState("O");
  const [selectedInstitute, setSelectedInstitute] = useState(null);

  // specify no of recurrings
  const [noOfRecurringsError, setNoOfRecurringsError] = useState(false);
  const [noOfRecurrings, setNoOfRecurrings] = useState("");

  // updating no of recurrings
  useEffect(() => {
    let noOfFrequency = 1;

    if (selectedFrequency === "D") {
      noOfFrequency = 30;
    } else if (selectedFrequency === "W") {
      noOfFrequency = 52;
    } else if (selectedFrequency === "B") {
      noOfFrequency = 26;
    } else if (selectedFrequency === "M") {
      noOfFrequency = 12;
    } else if (selectedFrequency === "Q") {
      noOfFrequency = 4;
    } else if (selectedFrequency === "O") {
      noOfFrequency = 1;
    }

    setNoOfRecurrings(noOfFrequency);
    setNoOfRecurringsError(false);
  }, [selectedFrequency]);

  // handle input box event for no of recurrings
  const handleNoOfRecurrings = (value) => {
    let newValue = parseInt(value);
    if (newValue < 2) {
      setNoOfRecurringsError(true);
    } else if (newValue >= 2) {
      setNoOfRecurringsError(false);
    }
    if (newValue < 0) {
      newValue = 0;
    }
    setNoOfRecurrings(newValue);
  };

  // start date
  const [startDate, setStartDate] = useState(new Date());

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  // hiding the past dates
  const isSelectableDate = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison
    return date >= currentDate;
  };

  // transit number
  const [transitNumber, setTransitNumber] = useState("");
  const handleTransitNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 10.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 10);
    setTransitNumber(sanitizedInput);
  };

  // a/c number
  const [accountNumber, setAccountNumber] = useState("");
  const handleAccountNumber = (event) => {
    const input = event.target.value;
    // Use a regular expression to allow only digits and limit the length to 20.
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 20);
    setAccountNumber(sanitizedInput);
  };

  // add E transaction
  const handleAddETransaction = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      accountHolderName,
      campaignTag: selectedCampaign.value,
      email: transactionEmail,
      amount: parseFloat(amount),
      billingPeriod: selectedFrequency,
      totalPayments: parseFloat(noOfRecurrings),
      startDate: formatDate(startDate),
      instituteNumber: selectedInstitute,
      transitNumber,
      accountNumber: String(accountNumber),
      accountCode: null,
    };
    dispatch(addECheckTransaction(singleObj)).then((res) => {
      if (!res.payload.axiosError) {
        setNewECheckTransactionPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        dispatch(clearAddETransferModalStates());
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  // browse step
  const [browseStep, setBrowseStep] = useState(false);

  // selected email
  const [selectedEmail, setSelectedEmail] = useState("");

  // selected object
  const [selectedObj, setSelectedObj] = useState(null);

  // clear banking details redux state on component mount
  useEffect(() => {
    dispatch(clearBankingDetailsStates());
  }, []);

  // handle search email
  const handleSearchEmail = (e) => {
    e?.preventDefault();
    setSelectedEmail(transactionEmail);
    if (
      transactionEmail &&
      !bankingDetailsLoading &&
      (browseStep || transactionEmail !== selectedEmail)
    ) {
      dispatch(
        getBankingDetails({
          token,
          email: transactionEmail,
        })
      ).then((res) => {
        if (res.payload.axiosError === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    }
  };

  // handle confirm
  const handleConfirm = () => {
    setBrowseStep(false);
    setSuggestions([]);
    if (selectedObj) {
      setAccountHolderName(selectedObj.accountHolderName);
      setSelectedInstitute(selectedObj.instituteNumber);
      setTransitNumber(selectedObj.transitNumber);
      setAccountNumber(selectedObj.accountNumber);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          {!addDonorStep ? (
            <>
              {!browseStep ? (
                <>
                  <div className="update-widgets-modal-header">
                    <div>Add eCheck Transaction</div>
                    <div
                      className="cancel-widget-update-icon"
                      onClick={handleCloseModal}
                    >
                      <Icon icon={x} size={16} />
                    </div>
                  </div>
                  <div className="update-widgets-modal-body">
                    <form
                      className="update-media-form"
                      onSubmit={handleAddETransaction}
                    >
                      <MDBRow className="mb-3">
                        {/* email */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                          <label htmlFor="email">Email</label>
                          <div className="email-input-container">
                            <input
                              type="email"
                              className="edit-media-inputs no-borders-right"
                              placeholder="Email"
                              value={transactionEmail}
                              onChange={(e) =>
                                handleTransactionEmail(e.target.value)
                              }
                              required
                            />

                            {/* suggestion popup */}
                            {suggestions.length > 0 && (
                              <div className="suggestions-popup">
                                {suggestions.map((suggestion, index) => (
                                  <div
                                    key={index}
                                    className="suggestion-item"
                                    onClick={() => {
                                      setTransactionEmail(suggestion.email);
                                      setSuggestions([]);
                                    }}
                                  >
                                    <div className="suggestion-email">
                                      {suggestion.email}
                                    </div>
                                    <div className="suggestion-name">
                                      {suggestion.firstName}{" "}
                                      {suggestion.lastName}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            {/* add donor button */}
                            <Tippy content="Add Donor" zIndex={1000001}>
                              <button
                                type="button"
                                className="add-button"
                                onClick={() => setAddDonorStep(true)}
                                disabled={addETransactionLoading}
                              >
                                <Icon icon={userPlus} size={20} />
                              </button>
                            </Tippy>
                          </div>
                        </MDBCol>
                        {/* name */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                          <label htmlFor="accountholder">Account Title</label>
                          <div className="email-input-container">
                            <input
                              type="text"
                              className="edit-media-inputs"
                              placeholder="Account Title"
                              required
                              value={accountHolderName}
                              onChange={(e) =>
                                setAccountHolderName(e.target.value)
                              }
                            />
                            {/* browse button */}
                            <Tippy
                              content="Browse Banking Details"
                              zIndex={1000001}
                            >
                              <button
                                type="button"
                                className="add-button"
                                onClick={() => {
                                  if (transactionEmail) {
                                    setBrowseStep(true);
                                    handleSearchEmail();
                                  }
                                }}
                                disabled={addETransactionLoading}
                                style={{
                                  pointerEvents: !transactionEmail
                                    ? "none"
                                    : "auto",
                                  opacity: !transactionEmail ? 0.5 : 1,
                                }}
                              >
                                <Icon icon={search} size={20} />
                              </button>
                            </Tippy>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mb-3">
                        {/* campaign */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                          <label htmlFor="campaign">Campaign</label>
                          <Select
                            styles={styles}
                            required
                            menuPlacement="bottom"
                            value={campaignOptions.find(
                              (option) =>
                                option.value === selectedCampaign.value
                            )}
                            onChange={(option) => setSelectedCampaign(option)}
                            options={campaignOptions}
                          />
                        </MDBCol>
                        {/* amount */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                          <label htmlFor="amount">Amount</label>
                          {selectedCampaign.minimumAmount !== "" && (
                            <span
                              style={{
                                marginLeft: 10 + "px",
                                fontSize: 13 + "px",
                                fontWeight: 600,
                                color: "#6a6c6f",
                              }}
                            >
                              Minimum Amount:{" "}
                              {selectedOrganization?.currencySymbol}
                              {selectedCampaign.minimumAmount}
                            </span>
                          )}
                          <CustomTextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            size="small"
                            value={amount}
                            onChange={handleAmountChange}
                            inputMode="decimal"
                            title=""
                            required
                            placeholder="Amount"
                            helperText={
                              amount !== "" &&
                              amount < selectedCampaign.minimumAmount
                                ? `Please enter an amount greater than or equal to ${selectedCampaign.minimumAmount}`
                                : ""
                            }
                            error={
                              amount !== "" &&
                              amount < selectedCampaign.minimumAmount
                            }
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mb-3">
                        {/* frequency */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                          <label htmlFor="frequency">Frequency</label>
                          <Select
                            styles={styles}
                            required
                            menuPlacement="bottom"
                            value={frequencyOptions.find(
                              (option) => option.value === selectedFrequency
                            )}
                            onChange={(option) =>
                              setSelectedFrequency(option.value)
                            }
                            options={frequencyOptions}
                          />
                        </MDBCol>
                        {/* start date */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-mb-0">
                          <label htmlFor="startDate">Start Date</label>
                          <ReactDatePicker
                            style={{
                              width: 100 + "%",
                            }}
                            className="edit-media-inputs"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            isClearable
                            placeholderText="Start Date"
                            required
                            minDate={new Date()}
                            filterDate={isSelectableDate}
                          />
                        </MDBCol>
                      </MDBRow>
                      {/* if selectedFrequency is anything other than one-time */}
                      {selectedFrequency !== "O" && (
                        <MDBRow className="mb-3">
                          <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                            <label htmlFor="noOfDonations">
                              No. Of Donations{" "}
                            </label>
                            <input
                              type="number"
                              className="edit-media-inputs"
                              value={noOfRecurrings}
                              onChange={(e) =>
                                handleNoOfRecurrings(e.target.value)
                              }
                              required
                              placeholder="No. Of Donations"
                            />
                            <div
                              className={
                                noOfRecurringsError ? "error-msg" : "info-msg"
                              }
                              style={{ marginTop: 5 + "px" }}
                            >
                              Minimum Recurrings: 02
                            </div>
                          </MDBCol>
                        </MDBRow>
                      )}
                      <MDBRow className="mb-3">
                        {/* institute name */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                          <label htmlFor="instituteName">Institute Name</label>
                          <Select
                            styles={styles}
                            required
                            menuPlacement="top"
                            value={instituteOptions.find(
                              (option) => option.value === selectedInstitute
                            )}
                            onChange={(option) =>
                              setSelectedInstitute(option.value)
                            }
                            options={instituteOptions}
                          />
                        </MDBCol>
                        {/* transit number */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                          <label htmlFor="transitNumber">
                            Transit#
                            <Tippy
                              content={<img src={chequeSample} alt="cheque" />}
                              zIndex={100001}
                              placement="top"
                              maxWidth={100 + "%"}
                            >
                              <span
                                style={{
                                  marginLeft: 5 + "px",
                                  cursor: "pointer",
                                }}
                              >
                                <Icon icon={info} size={18} />
                              </span>
                            </Tippy>
                          </label>
                          <CustomTextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            size="small"
                            value={transitNumber}
                            onChange={handleTransitNumber}
                            title=""
                            required
                            placeholder="Transit#"
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mb-3">
                        {/* account number */}
                        <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                          <label htmlFor="accountNumber">
                            Account#
                            <Tippy
                              content={<img src={chequeSample} alt="cheque" />}
                              zIndex={100001}
                              placement="top-end"
                              maxWidth={100 + "%"}
                            >
                              <span
                                style={{
                                  marginLeft: 5 + "px",
                                  cursor: "pointer",
                                }}
                              >
                                <Icon icon={info} size={18} />
                              </span>
                            </Tippy>
                          </label>
                          <CustomTextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            size="small"
                            value={accountNumber}
                            onChange={handleAccountNumber}
                            title=""
                            required
                            placeholder="Account#"
                            helperText={
                              accountNumber !== "" && accountNumber.length < 7
                                ? `Account Number must be at least 7 numeric digits`
                                : ""
                            }
                            error={
                              accountNumber !== "" && accountNumber.length < 7
                            }
                          />
                        </MDBCol>
                      </MDBRow>

                      {addETransactionData &&
                        (addETransactionData.apiError ||
                          addETransactionData.axiosError) && (
                          <div className="error-msg">
                            {addETransactionData.apiError ? (
                              <>
                                {typeof addETransactionData.apiError ===
                                "object" ? (
                                  // Case 1: If apiError is an object
                                  Object.keys(addETransactionData.apiError).map(
                                    (field, index) => (
                                      <p
                                        key={index}
                                        style={{ marginBottom: 0 }}
                                      >
                                        {addETransactionData.apiError[field][0]}
                                      </p>
                                    )
                                  )
                                ) : (
                                  // Case 2: If apiError is a simple string
                                  <>{addETransactionData.apiError}</>
                                )}
                              </>
                            ) : (
                              <>{addETransactionData.axiosError}</>
                            )}
                          </div>
                        )}

                      {/* cancel and next button */}
                      <div className="submit-and-cancel-div">
                        <button
                          type="button"
                          className="cancel"
                          onClick={handleCloseModal}
                        >
                          CANCEL
                        </button>
                        <button
                          type="submit"
                          className="submit addModal"
                          disabled={addETransactionLoading}
                          style={{
                            opacity:
                              (noOfRecurrings < 2 &&
                                selectedFrequency !== "O") ||
                              amount < selectedCampaign.minimumAmount ||
                              accountNumber.length < 7
                                ? 0.5
                                : 1,
                            pointerEvents:
                              (noOfRecurrings < 2 &&
                                selectedFrequency !== "O") ||
                              amount < selectedCampaign.minimumAmount ||
                              accountNumber.length < 7
                                ? "none"
                                : "auto",
                          }}
                        >
                          {addETransactionLoading ? (
                            <MetroSpinner
                              loading={addETransactionLoading}
                              size={22}
                              color="#fff"
                            />
                          ) : (
                            "ADD"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="update-widgets-modal-header">
                    <div>Browse Banking Details</div>
                    <div
                      className="cancel-widget-update-icon"
                      onClick={() => {
                        if (!bankingDetailsLoading) {
                          setBrowseStep(false);
                          setSuggestions([]);
                          setSelectedObj(null);
                        }
                      }}
                    >
                      <Icon icon={x} size={16} />
                    </div>
                  </div>
                  <div className="update-widgets-modal-body">
                    <MDBRow className="mb-3">
                      {/* email */}
                      <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                        <form onSubmit={handleSearchEmail}>
                          <label htmlFor="email">Email</label>
                          <div className="email-input-container">
                            <input
                              type="email"
                              className="edit-media-inputs"
                              placeholder="Search"
                              value={transactionEmail}
                              onChange={(e) => {
                                handleTransactionEmail(e.target.value);
                              }}
                              required
                              readOnly={bankingDetailsLoading}
                            />
                            {/* search button */}
                            <Tippy content="Search" zIndex={1000001}>
                              <button
                                type="submit"
                                className="add-button"
                                disabled={bankingDetailsLoading}
                              >
                                <Icon icon={search} size={20} />
                              </button>
                            </Tippy>
                          </div>
                        </form>
                      </MDBCol>
                    </MDBRow>

                    {bankingDetailsLoading ? (
                      <div
                        style={{
                          padding: 50 + "px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MetroSpinner
                          size={30}
                          color="#007C16"
                          loading={bankingDetailsLoading}
                        />
                      </div>
                    ) : (
                      <>
                        {bankingDetailsData &&
                        (bankingDetailsData.apiError ||
                          bankingDetailsData.axiosError) ? (
                          <div
                            style={{
                              padding: 50 + "px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="error-msg">
                              {bankingDetailsData.apiError ? (
                                <>
                                  {typeof bankingDetailsData.apiError ===
                                  "object" ? (
                                    // Case 1: If apiError is an object
                                    Object.keys(
                                      bankingDetailsData.apiError
                                    ).map((field, index) => (
                                      <p
                                        key={index}
                                        style={{ marginBottom: 0 }}
                                      >
                                        {bankingDetailsData.apiError[field][0]}
                                      </p>
                                    ))
                                  ) : (
                                    // Case 2: If apiError is a simple string
                                    <>{bankingDetailsData.apiError}</>
                                  )}
                                </>
                              ) : (
                                <>{bankingDetailsData.axiosError}</>
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            {bankingDetailsData?.data?.length > 0 ? (
                              <>
                                <h5 style={{ fontWeight: 600 }}>
                                  Select a row below to autocomplete banking
                                  details:
                                </h5>
                                <div className="transactions-table">
                                  <MDBTable align="middle">
                                    <MDBTableHead
                                      className="thead"
                                      style={{ zIndex: 0 }}
                                    >
                                      <tr>
                                        <th scope="col">Account Title</th>
                                        <th scope="col">Institute Name</th>
                                        <th scope="col">Institute#</th>
                                        <th scope="col">Transit#</th>
                                        <th scope="col">Account#</th>
                                      </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                      {bankingDetailsData.data.map(
                                        (data, index) => {
                                          return (
                                            <tr
                                              style={{
                                                backgroundColor:
                                                  selectedObj === data
                                                    ? "rgba(0, 124, 22, 0.1)"
                                                    : "#fff",
                                                cursor: "pointer",
                                              }}
                                              key={index}
                                              onClick={() => {
                                                setSelectedObj(data);
                                              }}
                                            >
                                              <td>
                                                <p
                                                  className="fw-normal mb-1"
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {data.accountHolderName}
                                                </p>
                                              </td>
                                              <td>
                                                <p
                                                  className="fw-normal mb-1"
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {data.institutionName}
                                                </p>
                                              </td>
                                              <td>
                                                <p
                                                  className="fw-normal mb-1"
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {data.instituteNumber}
                                                </p>
                                              </td>
                                              <td>
                                                <p
                                                  className="fw-normal mb-1"
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {data.transitNumber}
                                                </p>
                                              </td>
                                              <td>
                                                <p
                                                  className="fw-normal mb-1"
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {data.accountNumber}
                                                </p>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </MDBTableBody>
                                  </MDBTable>
                                </div>
                              </>
                            ) : (
                              <div
                                className="no-data-found-div"
                                style={{ padding: 50 + "px" }}
                              >
                                <Tippy content="No Data Found" zIndex={100005}>
                                  <img src={noDataFound} alt="No Data Found" />
                                </Tippy>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <div className="submit-and-cancel-div">
                      <button
                        type="button"
                        className="cancel"
                        onClick={() => {
                          if (!bankingDetailsLoading) {
                            setBrowseStep(false);
                            setSuggestions([]);
                            setSelectedObj(null);
                          }
                        }}
                      >
                        BACK
                      </button>
                      {bankingDetailsData?.data?.length > 0 && selectedObj && (
                        <button
                          type="button"
                          className="submit addModal"
                          onClick={handleConfirm}
                        >
                          CONFIRM
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <AddDonorModal
              token={token}
              setNewECheckTransactionPopup={setNewECheckTransactionPopup}
              countries={countries}
              usaStates={usaStates}
              provinces={provinces}
              addDonorStep={addDonorStep}
              setAddDonorStep={setAddDonorStep}
              setTransactionEmail={setTransactionEmail}
              setSuggestions={setSuggestions}
            />
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddECheckTransactionPopup;
