/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { basic_message_txt } from "react-icons-kit/linea/basic_message_txt";
import { basic_paperplane } from "react-icons-kit/linea/basic_paperplane";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearRefundAmountData,
  getRefundableTransactions,
  refundAmount,
  sendEmail,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import {
  MDBCol,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

const RefundPopup = ({
  token,
  setTransactionTypePopup,
  transactionTypeObj,
}) => {
  // redux state
  const {
    refundableTransactionsLoading,
    refundableTransactionsData,
    refundableTransactionsError,
    transactionsData, // currency symbol
    refundAmountLoading,
    refundAmountData,
    sendEmailLoading,
    sendEmailData,
  } = useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    if (
      !refundableTransactionsLoading &&
      !refundAmountLoading &&
      !sendEmailLoading
    ) {
      setTransactionTypePopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get refundable transactions
  useEffect(() => {
    dispatch(
      getRefundableTransactions({
        token,
        tagNumber: transactionTypeObj.transactionTag,
      })
    ).then((res) => {
      // 401 works fine here
      if (
        res.error &&
        res.error.message === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  }, []);

  // amount
  const [amount, setAmount] = useState("");

  // set default amount to eligibleAmount
  useEffect(() => {
    if (
      refundableTransactionsData &&
      refundableTransactionsData.eligibleRefundAmount !== 0
    ) {
      setAmount(refundableTransactionsData.eligibleRefundAmount);
    } else {
      setAmount("");
    }
  }, [refundableTransactionsData]);

  // amount change
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // notes
  const [notes, setNotes] = useState("");

  // handle refund
  const handleRefund = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      tagNumber: transactionTypeObj.transactionTag,
      amount: parseFloat(amount),
      userNotes: notes,
    };
    if (parseFloat(amount) <= refundableTransactionsData.eligibleRefundAmount) {
      dispatch(refundAmount(singleObj)).then((res) => {
        if (!res.payload.axiosError) {
          setTransactionTypePopup(false);
          toast.success(`${res.payload.successMsg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          res.payload.axiosError === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    }
  };

  // email change event
  const handleSendEmail = (refundedTransaction) => {
    dispatch(
      sendEmail({
        token,
        tagNumber: refundedTransaction.tagNumber,
      })
    ).then((res) => {
      if (!res.payload.error) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (res.payload.error === "Request failed with status code 401") {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.payload.error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // clear redux refund amount state data on component mount
  useEffect(() => {
    dispatch(clearRefundAmountData());
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Refund Transaction</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            {refundableTransactionsLoading ? (
              <div
                style={{
                  padding: 50 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MetroSpinner
                  size={30}
                  color="#007C16"
                  loading={refundableTransactionsLoading}
                />
              </div>
            ) : (
              <>
                {refundableTransactionsError ? (
                  <div
                    style={{
                      padding: 50 + "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="error-msg">
                      {refundableTransactionsError}
                    </div>
                  </div>
                ) : (
                  <>
                    {refundableTransactionsData && (
                      <>
                        {refundableTransactionsData?.data?.length > 0 ? (
                          <div
                            className="transactions-table"
                            style={{ maxHeight: "300px" }}
                          >
                            <MDBTable align="middle">
                              <MDBTableHead
                                className="thead"
                                style={{ zIndex: 0 }}
                              >
                                <tr>
                                  <th scope="col">Date</th>
                                  <th
                                    scope="col"
                                    style={{ textAlign: "right" }}
                                  >
                                    Amount
                                  </th>
                                  <th scope="col">Invoice</th>
                                  <th
                                    scope="col"
                                    style={{ textAlign: "center" }}
                                  >
                                    Fee
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ textAlign: "center" }}
                                  >
                                    Approval Code
                                  </th>
                                  <th scope="col">Notes</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </MDBTableHead>

                              <MDBTableBody>
                                {refundableTransactionsData.data.map(
                                  (data, index) => (
                                    <tr
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0 ? "#fff" : "#fafafa",
                                      }}
                                      key={data.tagNumber}
                                    >
                                      <td>
                                        <p
                                          className="fw-normal mb-1"
                                          style={{ fontSize: "16px" }}
                                        >
                                          {data.transactionDate}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          className="fw-normal mb-1"
                                          style={{
                                            fontSize: "16px",
                                            textAlign: "right",
                                          }}
                                        >
                                          {
                                            transactionsData?.data
                                              ?.currencySymbol
                                          }
                                          {data.approvedAmount}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          className="fw-normal mb-1"
                                          style={{
                                            fontSize: "16px",
                                          }}
                                        >
                                          {data.invoice}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          className="fw-normal mb-1"
                                          style={{
                                            fontSize: "16px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {data.fee}
                                        </p>
                                      </td>
                                      <td>
                                        <p
                                          className="fw-normal mb-1"
                                          style={{
                                            fontSize: "16px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {data.approvalCode}
                                        </p>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        <Tippy
                                          content={data.userNotes}
                                          zIndex={1000001}
                                        >
                                          <span>
                                            <Icon
                                              icon={basic_message_txt}
                                              size={20}
                                              style={{
                                                color: "#007c16",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </span>
                                        </Tippy>
                                      </td>
                                      <td className="icons-td">
                                        <div className="actions-icon-container">
                                          {/* send email */}
                                          {transactionTypeObj.email ? (
                                            <>
                                              {sendEmailData &&
                                              sendEmailData.tagNumber ===
                                                data.tagNumber &&
                                              sendEmailLoading === true ? (
                                                <span className="loader-span">
                                                  <MetroSpinner
                                                    loading={sendEmailLoading}
                                                    color="#007c16"
                                                    size={20}
                                                  />
                                                </span>
                                              ) : (
                                                <Tippy
                                                  content="Send Email"
                                                  zIndex={1000001}
                                                >
                                                  <span
                                                    onClick={() =>
                                                      handleSendEmail(data)
                                                    }
                                                  >
                                                    <Icon
                                                      icon={basic_paperplane}
                                                      size={20}
                                                    />
                                                  </span>
                                                </Tippy>
                                              )}
                                            </>
                                          ) : (
                                            <Tippy
                                              content="Email Not Available"
                                              zIndex={10004}
                                            >
                                              <span>
                                                <Icon
                                                  icon={basic_paperplane}
                                                  size={20}
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: 0.5,
                                                  }}
                                                />
                                              </span>
                                            </Tippy>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </MDBTableBody>
                            </MDBTable>
                          </div>
                        ) : (
                          <div
                            className="no-data-found-div"
                            style={{ padding: 50 + "px" }}
                          >
                            <Tippy
                              content="No Refunded Transactions Found"
                              zIndex={100005}
                            >
                              <img
                                src={noDataFound}
                                alt="No Refunded Transactions Found"
                              />
                            </Tippy>
                          </div>
                        )}

                        {refundAmountData &&
                          refundAmountData.tagNumber ===
                            transactionTypeObj.transactionTag &&
                          (refundAmountData.apiError ||
                            refundAmountData.axiosError) && (
                            <div className="error-msg">
                              {refundAmountData.apiError ? (
                                <>
                                  {typeof refundAmountData.apiError ===
                                  "object" ? (
                                    // Case 1: If apiError is an object
                                    Object.keys(refundAmountData.apiError).map(
                                      (field, index) => (
                                        <p
                                          key={index}
                                          style={{ marginBottom: 0 }}
                                        >
                                          {refundAmountData.apiError[field][0]}
                                        </p>
                                      )
                                    )
                                  ) : (
                                    // Case 2: If apiError is a simple string
                                    <>{refundAmountData.apiError}</>
                                  )}
                                </>
                              ) : (
                                <>{refundAmountData.axiosError}</>
                              )}
                            </div>
                          )}

                        <form onSubmit={handleRefund}>
                          <MDBRow className="mb-3">
                            <MDBCol className="col-12 col-md-3 mb-2 mb-md-0">
                              <span
                                style={{
                                  fontSize: 13 + "px",
                                  fontWeight: 600,
                                  color: "#6a6c6f",
                                }}
                              >
                                Max Amount:{" "}
                                {transactionsData?.data?.currencySymbol}
                                {
                                  refundableTransactionsData.eligibleRefundAmount
                                }
                              </span>
                              <CustomTextField
                                type="text"
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                size="small"
                                value={amount}
                                onChange={handleAmountChange}
                                required
                                placeholder="Amount"
                                helperText={
                                  amount !== "" &&
                                  amount >
                                    refundableTransactionsData.eligibleRefundAmount
                                    ? `Please enter an amount less than or equal to ${refundableTransactionsData.eligibleRefundAmount}`
                                    : ""
                                }
                                error={
                                  amount !== "" &&
                                  amount >
                                    refundableTransactionsData.eligibleRefundAmount
                                }
                                disabled={
                                  refundableTransactionsData.eligibleRefundAmount ===
                                  0
                                }
                              />
                            </MDBCol>
                            <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                              <label>Notes</label>
                              <input
                                type="text"
                                className="edit-media-inputs"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                placeholder="Notes"
                                required
                              />
                            </MDBCol>
                            <MDBCol className="col-12 col-md-3 mb-2 mb-md-0">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "25px",
                                }}
                              >
                                <button
                                  type="submit"
                                  className="custom-btn green"
                                  disabled={
                                    refundAmountLoading ||
                                    refundableTransactionsLoading ||
                                    sendEmailLoading
                                  }
                                  style={{
                                    opacity:
                                      refundableTransactionsData.eligibleRefundAmount ===
                                        0 ||
                                      amount >
                                        refundableTransactionsData.eligibleRefundAmount
                                        ? 0.5
                                        : 1,
                                    pointerEvents:
                                      refundableTransactionsData.eligibleRefundAmount ===
                                        0 ||
                                      amount >
                                        refundableTransactionsData.eligibleRefundAmount
                                        ? "none"
                                        : "auto",
                                  }}
                                >
                                  {refundAmountLoading ? (
                                    <MetroSpinner
                                      color="#fff"
                                      size={22}
                                      loading={refundAmountLoading}
                                    />
                                  ) : (
                                    "REFUND"
                                  )}
                                </button>
                                <button
                                  type="button"
                                  className="custom-btn grey"
                                  style={{ marginLeft: "3px" }}
                                  onClick={handleCloseModal}
                                >
                                  CANCEL
                                </button>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </form>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default RefundPopup;
