import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteDonor,
  clearDeleteDonorModalStates,
} from "../../Store/Slices/Sidebar/Donors/DonorsCrudSlice";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const DeleteDonorModal = ({ token, setDeleteDonorModal, deleteDonorObj }) => {
  // redux state
  const { deleteDonorLoading, deleteDonorData } = useSelector(
    (state) => state.donors
  );

  // close modal
  const handleCloseModal = () => {
    if (!deleteDonorLoading) {
      setDeleteDonorModal(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // delete donor
  const handleDeleteDonor = () => {
    dispatch(deleteDonor({ token, tagNumber: deleteDonorObj.tagNumber })).then(
      (res) => {
        if (!res.payload.axiosError) {
          setDeleteDonorModal(false);
          toast.success(`${res.payload.successMsg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          res.payload.axiosError === "Request failed with status code 401"
        ) {
          dispatch(clearDeleteDonorModalStates());
          localStorage.removeItem("user");
          navigate("/login");
        }
      }
    );
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              Delete Donor - {deleteDonorObj.firstName}{" "}
              {deleteDonorObj.lastName}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#DC3545" }}>
                Are you sure you want to delete this donor?
              </p>
              <p style={{ color: "#6a6c6f" }}>You cannot undo this action.</p>
            </div>
            {deleteDonorData &&
            deleteDonorData.apiError &&
            deleteDonorData.tagNumber === deleteDonorObj.tagNumber ? (
              <div className="error-msg">{deleteDonorData.apiError}</div>
            ) : (
              <>
                {deleteDonorData &&
                  deleteDonorData.axiosError &&
                  deleteDonorData.tagNumber === deleteDonorObj.tagNumber && (
                    <div className="error-msg">
                      {deleteDonorData.axiosError}
                    </div>
                  )}
              </>
            )}
            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="submit delete"
                onClick={handleDeleteDonor}
                disabled={deleteDonorLoading}
              >
                {deleteDonorLoading ? (
                  <MetroSpinner
                    loading={deleteDonorLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  "DELETE"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DeleteDonorModal;
