import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";
import { deleteTransaction } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const DeleteTransactionPopup = ({
  token,
  setDeleteTransactionPopup,
  deleteTransactionObj,
}) => {
  // redux state
  const { deleteTransactionLoading, deleteTransactionData } = useSelector(
    (state) => state.transactions
  );

  // close modal
  const handleCloseModal = () => {
    if (!deleteTransactionLoading) {
      setDeleteTransactionPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // delete transaction
  const handleDeleteTransaction = () => {
    dispatch(
      deleteTransaction({
        token,
        tagNumber: deleteTransactionObj.transactionTag,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        setDeleteTransactionPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              Delete Transaction - {deleteTransactionObj.transactionTag}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#DC3545" }}>
                Are you sure you want to delete this transaction?
              </p>
              <p style={{ color: "#6a6c6f" }}>You cannot undo this action.</p>
            </div>

            {deleteTransactionData &&
              deleteTransactionData.tagNumber ===
                deleteTransactionObj.transactionTag &&
              (deleteTransactionData.apiError ||
                deleteTransactionData.axiosError) && (
                <div className="error-msg">
                  {deleteTransactionData.apiError ||
                    deleteTransactionData.axiosError}
                </div>
              )}

            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                className="submit delete"
                disabled={deleteTransactionLoading}
                onClick={handleDeleteTransaction}
              >
                {deleteTransactionLoading ? (
                  <MetroSpinner
                    loading={deleteTransactionLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  "DELETE"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DeleteTransactionPopup;
