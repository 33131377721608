/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../../CSS/Sidebar/Campaign.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteModalMount,
  filterCampaign,
  getCampaign,
  listOfValues,
  updateModalMount,
} from "../../Store/Slices/Sidebar/Campaigns/CampaignSlice";
import {
  getIcons,
  getNode,
} from "../../Store/Slices/Sidebar/Campaigns/GetIconsAndNodesSlice";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import toast, { Toaster } from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import AddCampaignModal from "../Campaign/AddCampaignModal";
import UpdateCampaignModal from "../Campaign/UpdateCampaignModal";
import DeleteCampaignModal from "../Campaign/DeleteCampaignModal";
import FilterCampaignDrawer from "../Campaign/FilterCampaignDrawer";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import axios from "axios";
import hostName from "../../config";
import Icon from "react-icons-kit";
import { basic_download } from "react-icons-kit/linea/basic_download";
import { copy } from "react-icons-kit/feather/copy";

// custom styles
const perPageStyle = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

function CampaignsComponent({ token }) {
  // redux state
  const {
    loader,
    error,
    campaignData,
    deleteSuccess,
    updateSuccessMessage,
    listOfValuesLoader,
  } = useSelector((state) => state.campaign);

  const { iconsLoader, nodeLoader } = useSelector(
    (state) => state.getIconsAndNodes
  );

  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("10");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("10");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // per page options
  const perPageOptionsList = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get icons, nodes and lov
  useEffect(() => {
    dispatch(getIcons(token));
    dispatch(getNode(token));
    dispatch(listOfValues({ token, listName: "campaignFilter" }));
  }, []);

  // update
  const [updCampaign, setUpdCampaign] = useState(false);
  const [updData, setUpdData] = useState({});

  // delete
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  // update
  const handleUpdate = (data) => {
    setUpdCampaign(true);
    setUpdData(data);
  };

  // delete campaign
  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  // delete suucess
  useEffect(() => {
    if (deleteSuccess) {
      setDeleteModal(false);
      toast.success(deleteSuccess, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(deleteModalMount());
    }
  }, [deleteSuccess]);

  // update success
  useEffect(() => {
    if (updateSuccessMessage) {
      setUpdCampaign(false);
      toast.success(updateSuccessMessage, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(updateModalMount());
    }
  }, [updateSuccessMessage]);

  // generate qr code

  // states
  const [qrCodeLoading, setQrCodeLoading] = useState(false);
  const [campaignTagNumber, setCampaignTagNumber] = useState(null);
  const [qrCodeError, setQrCodeError] = useState(null);

  // event
  const handleGenerateQrCode = (item) => {
    const word = item.name.replace(/[^a-zA-Z0-9]/g, "");
    const lowercase = word.toLowerCase();
    const finalURL = item.qrCode + lowercase;
    setQrCodeLoading(true);
    setCampaignTagNumber(item.tagNumber);
    setQrCodeError(false);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };

    axios
      .get(`${hostName}api/admin/v1/qrCode?data=${finalURL}`, axiosConfig)
      .then((response) => {
        // download png
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "qrcode.png");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setQrCodeError(error.message);
        }
      })
      .finally(() => setQrCodeLoading(false));
  };

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);

  // get campaign based on fiter exist or not
  useEffect(() => {
    if (!filteredParams) {
      dispatch(
        getCampaign({
          token,
          page: 1,
          pageSize: "10",
          order: "asc",
          by: "name",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      dispatch(
        filterCampaign({
          token,
          page: 1,
          pageSize: filteredParams.pageSize,
          order: filteredParams.order,
          apiData: filteredParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    }
  }, [filteredParams]);

  // handlePageSizeChange
  const handlePageSizeChange = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (!filteredParams) {
        dispatch(
          getCampaign({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            by: "name",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          filterCampaign({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (!filteredParams) {
        dispatch(
          getCampaign({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            by: "name",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          filterCampaign({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [loader, listOfValuesLoader, iconsLoader, nodeLoader];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // filter states
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(0);
  const [issueTaxReceipt, setIssueTaxReceipt] = useState(0);

  // sort by
  const [sortBy, setSortBy] = useState(null);
  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // handle boolean state if true convert in 1 otherwise 0
  const handleBoolean = (setState) => (event) => {
    if (event.target.checked) {
      setState(1);
    } else {
      setState(0);
    }
  };

  // handle start and end date change
  const handleDate = (setState) => (value) => {
    setState(value);
  };

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  // copy
  const handleCopy = (item) => {
    navigator.clipboard
      .writeText(
        item.qrCode + item.name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
      )
      .then(() => {
        toast.success(`Text copied to the clipboard`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="middle-area campaignComponents">
      <Toaster />
      {/* update/delete campaign pass token and object */}
      {updCampaign && (
        <UpdateCampaignModal
          token={token}
          open={updCampaign}
          setOpen={setUpdCampaign}
          data={updData}
        />
      )}
      {deleteModal && (
        <DeleteCampaignModal
          token={token}
          setOpen={setDeleteModal}
          id={deleteId}
        />
      )}
      {/* if loader true then add extra class height */}
      <div className={`middle-content flex-start ${loadings ? "height" : ""}`}>
        {loadings ? (
          <>
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          </>
        ) : (
          <>
            <div className="media-heading-button-container">
              <h6>Campaigns</h6>
              <div className="add-and-filter-btns-div">
                <div className="action-btns-div">
                  <AddCampaignModal token={token} />
                </div>
                <div className="filter-and-reset-btns-div">
                  <FilterCampaignDrawer
                    handleSelectChange={handleSelectChange}
                    entitySelected={entitySelected}
                    setEntitySelected={setEntitySelected}
                    filteredParams={filteredParams}
                    setFilteredParams={setFilteredParams}
                    name={name}
                    setName={setName}
                    description={description}
                    setDescription={setDescription}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    handleDate={handleDate}
                    formatDate={formatDate}
                    status={status}
                    setStatus={setStatus}
                    handleBoolean={handleBoolean}
                    issueTaxReceipt={issueTaxReceipt}
                    setIssueTaxReceipt={setIssueTaxReceipt}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    setLoadings={setLoadings}
                    perPage={perPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    handlePerPage={handlePerPage}
                  />
                </div>
              </div>
            </div>
            <div className="flexRow w-100">
              {/* error */}
              {error && <div className="error-msg">{error}</div>}
              {
                // if campaignData length is greater then 0 then map the array
                campaignData && campaignData.data.length > 0 ? (
                  <>
                    {campaignData.data.map((item) => (
                      <div key={item.tagNumber} className="mb-3 campaignCard">
                        <div
                          className={`h-100 ${
                            item.status
                              ? "borderTopGreen py-1"
                              : "borderTopYellow"
                          }`}
                        >
                          <div className="flexBetweenCenter mb-3 gap-20">
                            <h1 className="campaignTitle">{item.name}</h1>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {item.qrCode && (
                                <Tippy
                                  interactive
                                  content={
                                    <a
                                      href={
                                        item.qrCode +
                                        item.name
                                          .replace(/[^a-zA-Z0-9]/g, "")
                                          .toLowerCase()
                                      }
                                      target="blank"
                                      rel="noreferrer noopener"
                                      className="qrlink-anchor"
                                    >
                                      {item.qrCode +
                                        item.name
                                          .replace(/[^a-zA-Z0-9]/g, "")
                                          .toLowerCase()}
                                    </a>
                                  }
                                  maxWidth={`none`}
                                >
                                  <p
                                    className="mb-0"
                                    style={{
                                      marginRight: "5px",
                                      padding: "5px 10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleCopy(item)}
                                  >
                                    <Icon icon={copy} size={28} />
                                  </p>
                                </Tippy>
                              )}

                              <p
                                className={`mb-0 status ${
                                  item.donationCampaign
                                    ? "bgGreen text-white"
                                    : "bgYellow text-dark"
                                }`}
                                style={{
                                  marginRight: "5px",
                                  padding: "5px 20px",
                                }}
                              >
                                {item.donationCampaign ? "Donation" : "Payment"}
                              </p>
                              <p
                                className={`mb-0 status ${
                                  item.status
                                    ? "bgGreen text-white"
                                    : "bgYellow text-dark"
                                }`}
                                style={{ padding: "5px 20px" }}
                              >
                                {item.status ? "Active" : "Inactive"}
                              </p>
                            </div>
                          </div>
                          <div className="flexBetweenCenter mb-3 gap-20">
                            <span>
                              <p className="campaignDiscription">
                                {item.description}
                              </p>
                            </span>
                            <span>
                              <img
                                src={item.icon.filename}
                                alt={item.icon.tags}
                                style={{
                                  width: item.icon.width,
                                  height: item.icon.height,
                                }}
                                title={item.icon.tags}
                              />
                            </span>
                          </div>
                          <div className="campaignTable">
                            <MDBTable align="middle">
                              <MDBTableHead className="media-thead">
                                <tr>
                                  <th scope="col">Start Date</th>
                                  <th scope="col">End Date</th>
                                  <th scope="col">Minimum Amount</th>
                                  <th scope="col">Tax Deductible</th>
                                  <th scope="col">Sort Order</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody className="media-body">
                                <tr>
                                  <td>
                                    <p className="text-muted mb-0">
                                      {item.startDate?.split(" ")[0]}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">
                                      {item.endDate?.split(" ")[0]}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">
                                      {item.minimumAmount}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">
                                      <span className="fs-14 textGreen">
                                        {item.issueTaxReceipt ? "Yes" : "No"}
                                      </span>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="text-muted mb-0">
                                      {item.sortOrder}
                                    </p>
                                  </td>
                                </tr>
                              </MDBTableBody>
                            </MDBTable>
                          </div>
                          <div className="threeBox">
                            <div className="box">
                              <h5>Target Amount</h5>
                              <span>{item.targetAmount}</span>
                            </div>
                            <div className="box">
                              <h5>Campaign Collection</h5>
                              <span className="textGreen">{`${
                                item.currencySymbol
                              }${parseFloat(
                                item.raisedAmount.toFixed(2)
                              )}`}</span>
                            </div>
                            <div className="box">
                              <h5>Collection %</h5>
                              <span>
                                {item.targetAmount === 0
                                  ? 100
                                  : (
                                      (item.raisedAmount / item.targetAmount) *
                                      100
                                    ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="mb-3 progressContent">
                            <span
                              className="progress rounded"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span
                                className="progress-bar bgGreen"
                                style={{
                                  width: `${
                                    item.targetAmount === 0
                                      ? 100
                                      : (item.raisedAmount /
                                          item.targetAmount) *
                                        100
                                  }%`,
                                }}
                              ></span>
                            </span>
                          </div>
                          {qrCodeError &&
                            campaignTagNumber === item.tagNumber && (
                              <div className="error-msg">{qrCodeError}</div>
                            )}
                          <div className="footer-content">
                            <div>
                              <button
                                className="grey-btn"
                                onClick={() => handleGenerateQrCode(item)}
                                style={{
                                  opacity: !item.qrCode ? 0.5 : 1,
                                  pointerEvents: !item.qrCode ? "none" : "auto",
                                }}
                              >
                                {qrCodeLoading &&
                                campaignTagNumber === item.tagNumber ? (
                                  <MetroSpinner
                                    loading={qrCodeLoading}
                                    size={22}
                                    color="#fff"
                                  />
                                ) : (
                                  <>
                                    <Icon
                                      icon={basic_download}
                                      size={20}
                                      style={{ marginRight: 10 + "px" }}
                                    />
                                    QR Code
                                  </>
                                )}
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={() => handleUpdate(item)}
                                className="editBtn"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDelete(item.tagNumber)}
                                className="deleteBtn"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="per-page-and-pagination">
                      <div className="per-page mb-3 mb-md-0">
                        <label className="me-2">Records per page</label>
                        <Select
                          options={perPageOptionsList}
                          value={perPageOptionsList.find(
                            (state) => state.value === String(perPage)
                          )}
                          onChange={(option) =>
                            handlePageSizeChange(option.value)
                          }
                          styles={perPageStyle}
                          menuPlacement="top"
                        />
                      </div>
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </>
                ) : (
                  <div className="no-data-found-div">
                    <Tippy content="No Campaigns Found">
                      <img src={noDataFound} alt="No Campaigns Found" />
                    </Tippy>
                  </div>
                )
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CampaignsComponent;
