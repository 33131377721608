/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateDonor,
  clearEditDonorModalStates,
} from "../../Store/Slices/Sidebar/Donors/DonorsCrudSlice";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBBtnGroup, MDBCol, MDBRadio, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-hot-toast";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import apiKey from "../private/private";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const EditDonorModal = ({
  token,
  editDonorData,
  setEditDonorModal,
  countries,
  provinces,
  usaStates,
}) => {
  // hook
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce: 1000,
    options: {
      componentRestrictions: {
        country: ["ca", "us"],
      },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  // states
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const [placesPopup, setPlacesPopup] = useState(false);

  // input change
  const handleInputChange = (evt) => {
    const inputValue = evt.target.value;
    setStreetAddress(inputValue);
    getPlacePredictions({ input: inputValue });
    setSelectedPrediction(null);
    setPlacesPopup(true);
  };

  // prediction click
  const handlePredictionClick = (prediction) => {
    setSelectedPrediction(prediction);
    setPlacesPopup(false);

    // Extract address components from the prediction
    placesService?.getDetails(
      {
        placeId: prediction.place_id,
      },
      (place) => {
        const addressComponents = place.address_components;
        let mergedStreetAddress = "";

        // Loop through address components to find street number and route
        addressComponents.forEach((component) => {
          switch (component.types[0]) {
            case "street_number":
            case "route":
              mergedStreetAddress += `${component.long_name} `;
              break;
            case "locality":
              setCity(component.long_name);
              break;
            case "administrative_area_level_1":
              setProvinceState(component.short_name);
              break;
            case "country":
              setCountry(component.long_name);
              break;
            case "postal_code":
              setPostalZipCode(component.long_name);
              break;
            default:
              console.warn(
                "Unhandled address component type:",
                component.types[0]
              );
              // You can add additional handling for unhandled address component types here
              break;
          }
        });

        // Update the street address state variable with the merged street number and route
        setStreetAddress(mergedStreetAddress.trim());
      }
    );
  };

  // redux state
  const { updateDonorLoading, updateDonorData } = useSelector(
    (state) => state.donors
  );

  // close modal
  const handleCloseModal = () => {
    if (!updateDonorLoading) {
      setEditDonorModal(false);
    }
  };

  // input states
  // profile
  const [firstName, setFirstName] = useState(editDonorData.firstName);
  const [middleName, setMiddleName] = useState(editDonorData.middleName);
  const [lastName, setLastName] = useState(editDonorData.lastName);
  const [email, setEmail] = useState(editDonorData.email);
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState(editDonorData.phone);

  // address
  const [streetAddress, setStreetAddress] = useState(
    editDonorData.streetAddress
  );
  const [city, setCity] = useState(editDonorData.city);
  const [country, setCountry] = useState(editDonorData.country);
  const [provinceState, setProvinceState] = useState(
    editDonorData.provinceState
  );
  const [postalZipCode, setPostalZipCode] = useState(
    editDonorData.postalZipCode
  );
  const [pattern, setPattern] = useState("");

  useEffect(() => {
    if (country === "United States") {
      setPattern("^\\d{5}$|^\\d{5}-\\d{4}$|^\\d{5} \\d{4}$");
    } else {
      setPattern("^[A-Za-z]\\d[A-Za-z] \\d[A-Za-z]\\d$");
    }
  }, [country]);

  // donor type
  const [donorType, setDonorType] = useState(editDonorData.accountType);
  const handleDonorTypeChange = (event) => {
    setDonorType(event.target.id);
    setBusinessName("");
  };

  // business name
  const [businessName, setBusinessName] = useState(
    editDonorData.businessName ? editDonorData.businessName : ""
  );

  // country change
  const handleCountryChange = (label) => {
    setCountry(label);
    setProvinceState("");
    setPostalZipCode("");
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUpdateDonorSubmit = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      tagNumber: editDonorData.tagNumber,
      firstName,
      middleName,
      lastName,
      phone,
      email,
      // password,
      streetAddress,
      city,
      country,
      provinceState,
      postalZipCode,
      accountType: donorType,
    };
    if (donorType === "B") {
      singleObj.businessName = businessName;
    }
    dispatch(updateDonor(singleObj)).then((res) => {
      if (!res.payload.axiosError) {
        setEditDonorModal(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        dispatch(clearEditDonorModalStates());
        localStorage.removeItem("user");
        navigate("/login");
      } else if (res.payload.apiError) {
        setPlacesPopup(false);
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              Edit Donor - {editDonorData.firstName} {editDonorData.lastName}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form
              className="update-media-form"
              onSubmit={handleUpdateDonorSubmit}
            >
              <h5 className="auth-heading">Donor Type</h5>
              <MDBBtnGroup className="custom-btn-group">
                <MDBRadio
                  btn
                  btnColor={`${
                    donorType === "I" ? "theme-green" : "transparent"
                  }`}
                  id="I"
                  name="donorTypeOptions"
                  wrapperTag="span"
                  label="Individual"
                  checked={donorType === "I"}
                  onChange={handleDonorTypeChange}
                />
                <MDBRadio
                  btn
                  btnColor={`${
                    donorType === "B" ? "theme-green" : "transparent"
                  }`}
                  id="B"
                  name="donorTypeOptions"
                  wrapperClass="mx-2"
                  wrapperTag="span"
                  label="Business"
                  checked={donorType === "B"}
                  onChange={handleDonorTypeChange}
                />
              </MDBBtnGroup>

              {donorType === "B" && (
                <>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Business Name</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        required={donorType === "B"}
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                        placeholder="Business Name"
                      />
                    </MDBCol>
                  </MDBRow>
                </>
              )}

              <h4
                style={{
                  borderBottom: 1 + "px solid #e4e4e4",
                  paddingBottom: 5 + "px",
                  marginBottom: 15 + "px",
                }}
              >
                {donorType === "B" ? "Contact" : "Profile"}
              </h4>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    placeholder="First Name"
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                    placeholder="Middle Name"
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    placeholder="Last Name"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label>Phone</label>
                  <input
                    type="tel"
                    inputMode="numeric"
                    pattern="^[0-9]{10,15}$"
                    title="Please enter a valid phone number with 10 to 15 digits."
                    className="edit-media-inputs"
                    value={phone}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9]*$/.test(value) && value.length <= 15) {
                        setPhone(value);
                      }
                    }}
                    required
                    placeholder="Phone"
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label>Email</label>
                  <input
                    type="email"
                    className="edit-media-inputs"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Email"
                    readOnly
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label>Password</label>
                  <input
                    type="password"
                    className="edit-media-inputs"
                    value={password || ""}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    readOnly
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <h4
                style={{
                  borderBottom: 1 + "px solid #e4e4e4",
                  paddingBottom: 5 + "px",
                  marginBottom: 15 + "px",
                }}
              >
                Address
              </h4>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label>Street Address</label>
                  <div className="email-input-container">
                    <input
                      type="text"
                      className="edit-media-inputs"
                      value={streetAddress}
                      onChange={handleInputChange}
                      required
                      placeholder="Street Address"
                      autoComplete="new-street-alias"
                    />
                    {!isPlacePredictionsLoading &&
                      placePredictions.length > 0 &&
                      !selectedPrediction &&
                      placesPopup && (
                        <div className="suggestions-popup address">
                          {placePredictions.map((suggestion, index) => (
                            <div
                              key={index}
                              className="suggestion-item"
                              onClick={() => handlePredictionClick(suggestion)}
                            >
                              <div className="suggestion-email">
                                {suggestion.description}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                </MDBCol>
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label>City</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                    placeholder="City"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label>Country</label>
                  <Select
                    value={countries.find((option) => option.label === country)}
                    onChange={(option) => handleCountryChange(option.label)}
                    options={countries}
                    styles={styles}
                    required
                    menuPlacement="top"
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  {country === "Canada" ? (
                    <>
                      <label>Province</label>
                      <Select
                        value={provinces.find(
                          (option) => option.value === provinceState
                        )}
                        onChange={(option) => setProvinceState(option.value)}
                        options={provinces}
                        styles={styles}
                        required
                        menuPlacement="top"
                      />
                    </>
                  ) : (
                    <>
                      {country === "United States" ? (
                        <>
                          <label>State</label>
                          <Select
                            value={usaStates.find(
                              (option) => option.value === provinceState
                            )}
                            onChange={(option) =>
                              setProvinceState(option.value)
                            }
                            options={usaStates}
                            styles={styles}
                            required
                            menuPlacement="top"
                          />
                        </>
                      ) : (
                        <>
                          <label>Province</label>
                          <input
                            type="text"
                            className="edit-media-inputs"
                            value={provinceState}
                            onChange={(e) => setProvinceState(e.target.value)}
                            required
                            placeholder="Province"
                          />
                        </>
                      )}
                    </>
                  )}
                </MDBCol>
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label>
                    {country === "United States" ? "Zip Code" : "Postal Code"}
                  </label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    pattern={pattern}
                    title={
                      country === "United States"
                        ? "Please enter a valid zip code (e.g., 12345 or 12345-6789)."
                        : "Please enter a valid postal code (e.g., A1A 1A1)."
                    }
                    value={postalZipCode}
                    onChange={(e) => setPostalZipCode(e.target.value)}
                    required
                    placeholder={
                      country === "United States" ? "Zip Code" : "Postal Code"
                    }
                  />
                </MDBCol>
              </MDBRow>

              {updateDonorData &&
                updateDonorData.tagNumber === editDonorData.tagNumber &&
                (updateDonorData.apiError || updateDonorData.axiosError) && (
                  <div className="error-msg">
                    {updateDonorData.apiError ? (
                      <>
                        {typeof updateDonorData.apiError === "object" ? (
                          // Case 1: If apiError is an object
                          Object.keys(updateDonorData.apiError).map(
                            (field, index) => (
                              <p key={index} style={{ marginBottom: 0 }}>
                                {updateDonorData.apiError[field][0]}
                              </p>
                            )
                          )
                        ) : (
                          // Case 2: If apiError is a simple string
                          <>{updateDonorData.apiError}</>
                        )}
                      </>
                    ) : (
                      <>{updateDonorData.axiosError}</>
                    )}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit update"
                  disabled={updateDonorLoading}
                >
                  {updateDonorLoading ? (
                    <MetroSpinner
                      loading={updateDonorLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EditDonorModal;
