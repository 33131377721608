import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { xCircle } from "react-icons-kit/feather/xCircle";
import { userPlus } from "react-icons-kit/feather/userPlus";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateConfiguration } from "../../Store/Slices/Sidebar/Notifications/NotificationsSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import toast from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const EditConfigurationPopup = ({
  token,
  setEditConfigurationPopup,
  editConfigurationObj,
}) => {
  // redux state
  const { updateConfigurationLoading, updateConfigurationData } = useSelector(
    (state) => state.notifications
  );

  // close modal
  const handleCloseModal = () => {
    if (!updateConfigurationLoading) {
      setEditConfigurationPopup(false);
    }
  };

  // form states
  const [status, setStatus] = useState(editConfigurationObj.status);
  const [email, setEmail] = useState("");

  // status change
  const handleStatusChange = () => {
    setStatus(!status);
  };

  // email change event
  const handleEmail = (value) => {
    setEmail(value);
  };

  // emails state array
  const [emails, setEmails] = useState(editConfigurationObj.emails);

  // valid email
  const [emailError, setEmailError] = useState(null);

  // add new email
  const handleAddNewEmail = () => {
    if (email && isValidEmail(email)) {
      setEmailError(null);
      if (!emails.includes(email)) {
        const updatedEmails = [...emails, email];
        setEmails(updatedEmails);
      }
      setEmail("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  // email regex
  const isValidEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // remove email from emails array
  const handleRemoveEmail = (emailToRemove) => {
    const updatedEmails = emails.filter((email) => email !== emailToRemove);
    setEmails(updatedEmails);
  };

  // dispatch and use navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // form submit
  const handleUpdateConfiguration = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      tagNumber: editConfigurationObj.tagNumber,
      status,
      emails: emails.join(";"),
    };
    dispatch(updateConfiguration(singleObj)).then((res) => {
      if (!res.payload.axiosError) {
        setEditConfigurationPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              Edit Configuration - {editConfigurationObj.shortDescriptionEn}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form
              className="update-media-form"
              onSubmit={handleUpdateConfiguration}
            >
              {/* status */}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <h6>Status</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={status}
                      onChange={handleStatusChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </MDBCol>
              </MDBRow>

              {/* new email to add */}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>New Email</label>
                  <div className="email-input-container">
                    <input
                      type="email"
                      className="edit-media-inputs no-borders-right"
                      placeholder="New Email"
                      value={email}
                      onChange={(e) => handleEmail(e.target.value)}
                    />
                    <Tippy content="Add Email" zIndex={1000001}>
                      <button
                        type="button"
                        className="add-button"
                        onClick={handleAddNewEmail}
                      >
                        <Icon icon={userPlus} size={20} />
                      </button>
                    </Tippy>
                  </div>
                </MDBCol>
              </MDBRow>

              {/* email error */}
              {emailError && <div className="error-msg">{emailError}</div>}

              {/* render emails */}
              {emails.length > 0 && (
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <div className="email-list-container">
                      {emails.map((email, index) => (
                        <div className="configuration-email-item" key={index}>
                          <span>{email}</span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveEmail(email)}
                          >
                            <Icon icon={xCircle} size={18} />
                          </span>
                        </div>
                      ))}
                    </div>
                  </MDBCol>
                </MDBRow>
              )}

              {updateConfigurationData &&
              updateConfigurationData.apiError &&
              updateConfigurationData.tagNumber ===
                editConfigurationObj.tagNumber ? (
                <div className="error-msg">
                  {updateConfigurationData.apiError}
                </div>
              ) : (
                <>
                  {updateConfigurationData &&
                    updateConfigurationData.axiosError &&
                    updateConfigurationData.tagNumber ===
                      editConfigurationObj.tagNumber && (
                      <div className="error-msg">
                        {updateConfigurationData.axiosError}
                      </div>
                    )}
                </>
              )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={updateConfigurationLoading}
                >
                  {updateConfigurationLoading ? (
                    <MetroSpinner
                      loading={updateConfigurationLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EditConfigurationPopup;
