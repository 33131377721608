import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addMedia } from "../../Store/Slices/Sidebar/Media/GetMediaSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const AddMediaModal = ({ token, setAddMediaModal }) => {
  // redux state
  const { addMediaLoading, addMediaData } = useSelector((state) => state.media);

  // close modal
  const handleCloseModal = () => {
    if (!addMediaLoading) {
      setAddMediaModal(false);
    }
  };

  // form states
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const handleDurationChange = (value) => {
    let newValue = parseInt(value, 10);
    if (newValue < 1) {
      newValue = 1;
    }
    setDuration(newValue);
  };
  const [sortOrder, setSortOrder] = useState("");
  const handleSortOrder = (value) => {
    let newValue = parseInt(value, 10);
    if (newValue < 1) {
      newValue = 1;
    }
    setSortOrder(newValue);
  };
  const [mediaFile, setMediaFile] = useState(null);
  const [fileError, setFileError] = useState(null);

  const handleMediaFile = (file) => {
    if (file) {
      const allowedFileTypes = [
        "image/bmp",
        "image/gif",
        "image/png",
        "image/jpeg",
        "image/jpg",
        "video/mp4",
      ];
      const fileType = file.type;
      const maxFileSizeMB = 20; // Maximum file size in MB

      // Check file type
      if (!allowedFileTypes.includes(fileType)) {
        setMediaFile(null);
        setFileError(
          "Invalid file type. Only BMP, GIF, PNG, JPEG, JPG, and MP4 files are allowed."
        );
        return;
      }

      // Check file size
      if (file.size > maxFileSizeMB * 1024 * 1024) {
        setMediaFile(null);
        setFileError(`File size should not exceed ${maxFileSizeMB}MB.`);
        return;
      }

      // File is valid
      setMediaFile(file);
      setFileError(null);
    } else {
      setFileError(null);
    }
  };
  const [status, setStatus] = useState("false");
  // status change
  const handleStatusChange = () => {
    setStatus((prevStatus) => (prevStatus === "true" ? "false" : "true"));
  };

  const [muted, setMuted] = useState("false");
  // muted change
  const handleMutedChange = () => {
    setMuted((prevStatus) => (prevStatus === "true" ? "false" : "true"));
  };

  const [fullScreen, setFullScreen] = useState("false");
  // full screen change
  const handleFullScreenChange = () => {
    setFullScreen((prevStatus) => (prevStatus === "true" ? "false" : "true"));
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // form submit
  const handleAddMediaSubmit = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      name,
      description,
      duration,
      mediaFile,
      sortOrder,
      fullScreen,
      isMuted: muted,
      status,
    };
    dispatch(addMedia(singleObj)).then((res) => {
      if (!res.payload.axiosError) {
        setAddMediaModal(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Add Media</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form className="update-media-form" onSubmit={handleAddMediaSubmit}>
              {/* name */}
              <MDBRow className="mb-3">
                <MDBCol>
                  <label>Name</label>
                  <input
                    className="edit-media-inputs"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="Media Name"
                  />
                </MDBCol>
              </MDBRow>

              {/* description */}
              <MDBRow className="mb-3">
                <MDBCol>
                  <label>Description (Max 100 characters)</label>
                  <textarea
                    className="edit-media-inputs text-area"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    placeholder="Media Description"
                    maxLength={100}
                  ></textarea>
                </MDBCol>
              </MDBRow>

              {/* media */}
              <MDBRow className="mb-3">
                <MDBCol>
                  <label>
                    Media (Upload Image/Video files only. Max File Size 20MB)
                  </label>
                  <input
                    className="edit-media-inputs"
                    type="file"
                    onChange={(e) => handleMediaFile(e.target.files[0])}
                    accept=".bmp, .gif, .png, .jpeg, .jpg, .mp4"
                    required
                  />
                  {fileError && <div className="error-msg">{fileError}</div>}
                </MDBCol>
              </MDBRow>

              {/* duration sort order */}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label>Duration</label>
                  <input
                    className="edit-media-inputs"
                    type="number"
                    value={duration.toString()}
                    onChange={(e) => handleDurationChange(e.target.value)}
                    required
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label>Sort Order</label>
                  <input
                    className="edit-media-inputs"
                    type="number"
                    value={sortOrder.toString()}
                    onChange={(e) => handleSortOrder(e.target.value)}
                    required
                  />
                </MDBCol>
              </MDBRow>
              {/* status muted full screen*/}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <h6>Status</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={status === "true"}
                      onChange={handleStatusChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </MDBCol>
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <h6>Muted</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={muted === "true"}
                      onChange={handleMutedChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </MDBCol>
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <h6>Full Screen</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={fullScreen === "true"}
                      onChange={handleFullScreenChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </MDBCol>
              </MDBRow>
              {addMediaData && addMediaData.apiError && (
                <div className="error-msg">{addMediaData.apiError}</div>
              )}
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={addMediaLoading}
                  style={{
                    opacity: fileError ? 0.5 : 1,
                    pointerEvents: fileError ? "none" : "auto",
                  }}
                >
                  {addMediaLoading ? (
                    <MetroSpinner
                      loading={addMediaLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddMediaModal;
