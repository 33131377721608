/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../CSS/News/News.scss";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { chevronLeft } from "react-icons-kit/feather/chevronLeft";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../Store/Slices/News/NewsSlice";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import ReactPlayer from "react-player/youtube";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const NewsPopup = ({ setNewsPopup, shouldMute }) => {
  // redux state
  const { newsLoading, newsData, newsError } = useSelector(
    (state) => state.news
  );

  // close modal
  const handleCloseModal = () => {
    setNewsPopup(false);
  };

  // close the popup if user navigates away
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setNewsPopup(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [setNewsPopup]);

  // dispatch
  const dispatch = useDispatch();

  // get news
  useEffect(() => {
    if (!newsData || newsData.length === 0) {
      dispatch(getNews());
    }
  }, [dispatch]);

  // current index
  const [currentIndex, setCurrentIndex] = useState(0);

  // player ref
  const playerRefs = useRef([]);

  // duration
  const [duration, setDuration] = useState(0);

  // seconds left
  const [secondsLeft, setSecondsLeft] = useState(0);

  // Update duration and secondsLeft when newsData is updated
  useEffect(() => {
    if (newsData?.length > 0) {
      setDuration(newsData[0].duration);
      setSecondsLeft(newsData[0].duration);
    }
  }, [newsData]);

  // update seconds left every second
  useEffect(() => {
    if (duration > 0) {
      const interval = setInterval(() => {
        setSecondsLeft((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [duration]);

  // reset seconds left and change slide
  useEffect(() => {
    if (newsData && duration > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const newIndex =
            prevIndex === newsData.length - 1 ? 0 : prevIndex + 1;
          setDuration(newsData[newIndex].duration);
          setSecondsLeft(newsData[newIndex].duration);
          playerRefs.current.forEach((player, index) => {
            if (player && player.getInternalPlayer()) {
              try {
                if (newIndex === index) {
                  player.seekTo(0);
                  player.getInternalPlayer().playVideo();
                }
                player.getInternalPlayer().pauseVideo();
              } catch (error) {
                console.error("YouTube player error: ", error);
              }
            }
          });
          return newIndex;
        });
      }, duration * 1000 + 1000);

      return () => clearInterval(interval);
    }
  }, [duration, newsData, currentIndex]);

  // handle prev
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? newsData.length - 1 : prevIndex - 1;
      setDuration(newsData[newIndex].duration);
      setSecondsLeft(newsData[newIndex].duration);
      playerRefs.current.forEach((player, index) => {
        if (player && player.getInternalPlayer()) {
          try {
            if (newIndex === index) {
              player.seekTo(0);
              player.getInternalPlayer().playVideo();
            }
            player.getInternalPlayer().pauseVideo();
          } catch (error) {
            console.error("YouTube player error: ", error);
          }
        }
      });
      return newIndex;
    });
  };

  // handle next
  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === newsData.length - 1 ? 0 : prevIndex + 1;
      setDuration(newsData[newIndex].duration);
      setSecondsLeft(newsData[newIndex].duration);
      playerRefs.current.forEach((player, index) => {
        if (player && player.getInternalPlayer()) {
          try {
            if (newIndex === index) {
              player.seekTo(0);
              player.getInternalPlayer().playVideo();
            }
            player.getInternalPlayer().pauseVideo();
          } catch (error) {
            console.error("YouTube player error: ", error);
          }
        }
      });
      return newIndex;
    });
  };

  // Calculate the width percentage for the progress bar
  const progressBarWidth = `${(secondsLeft / duration) * 100}%`;

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <motion.div
          className="update-widgets-modal news-popup"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>New In Donor Choice</div>
            <div className="cancel-widget-update-icon">
              <Icon icon={x} size={16} onClick={handleCloseModal} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            {newsLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <MetroSpinner loading={newsLoading} size={30} color="#007c16" />
              </div>
            ) : (
              <>
                {newsError ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <div className="error-msg" style={{ width: "fit-content" }}>
                      {newsError}
                    </div>
                  </div>
                ) : (
                  <>
                    {newsData && newsData.length > 0 ? (
                      <div className="slideshow-wrapper">
                        <div className="slideshow-container">
                          {/* images/video */}
                          <div className="carousel-images">
                            <div className="current-img-div">
                              {newsData.map((slide, index) => (
                                <div
                                  key={index}
                                  className="current-image"
                                  style={{
                                    transform: `translateX(-${
                                      (currentIndex !== 0 && currentIndex) * 100
                                    }%)`,
                                  }}
                                >
                                  {slide.mediaType === "image" ? (
                                    <img
                                      src={slide.url}
                                      alt="currentImg"
                                      loading="lazy"
                                    />
                                  ) : (
                                    <div className="video-container">
                                      <ReactPlayer
                                        ref={(player) =>
                                          (playerRefs.current[index] = player)
                                        }
                                        url={slide.url}
                                        playing={currentIndex === index}
                                        width="100%"
                                        height="100%"
                                        playsinline
                                        key="youtube"
                                        controls
                                        muted={shouldMute}
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* content */}
                          <div className="carousel-content">
                            {newsData.map((slide, index) => (
                              <React.Fragment key={index}>
                                {index === currentIndex && (
                                  <AnimatePresence>
                                    <motion.div
                                      className="main-content"
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                      transition={{
                                        duration: 0.5,
                                        delay: 0.3,
                                        ease: "easeInOut",
                                      }}
                                      exit={{ opacity: 0 }}
                                    >
                                      <h2 className="name">{slide.name}</h2>
                                      <div
                                        className="description"
                                        dangerouslySetInnerHTML={{
                                          __html: slide.description,
                                        }}
                                      />
                                      <div className="progress-timer">
                                        <div className="progress-container">
                                          <div
                                            key={currentIndex}
                                            className="progress-bar"
                                            style={{ width: progressBarWidth }}
                                          ></div>
                                        </div>
                                      </div>
                                    </motion.div>
                                  </AnimatePresence>
                                )}
                              </React.Fragment>
                            ))}

                            <div className="secondary-content">
                              <div className="back-and-next-container">
                                <div
                                  className="back rounded-circle"
                                  onClick={handlePrev}
                                >
                                  <Icon icon={chevronLeft} size={42} />
                                </div>
                                <div className="indicator">{`${
                                  currentIndex + 1
                                } of ${newsData.length}`}</div>
                                <div
                                  className="next rounded-circle"
                                  onClick={handleNext}
                                >
                                  <Icon icon={chevronRight} size={42} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      >
                        <div className="no-data-found-div">
                          <Tippy content="No Slides Found" zIndex={1000001}>
                            <img src={noDataFound} alt="No Slides Found" />
                          </Tippy>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NewsPopup;
