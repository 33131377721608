import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import { MDBCheckbox } from "mdb-react-ui-kit";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const FilterTransactionsModal = ({
  setFilterTransactionsModal,
  entitySelected,
  entityOptions,
  handleSelectChange,
  setFilteredParams,
  approvedAmount,
  handleApprovedAmountChange,
  invoice,
  setInvoice,
  cardAccount,
  setCardAccount,
  approvalCode,
  setApprovalCode,
  email,
  setEmail,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  taxReceipt,
  setTaxReceipt,
  campaignTag,
  handleCampaignTagChange,
  nodeType,
  handleNodeTypeChange,
  nodeName,
  handleNodeNameChange,
  cardType,
  handleCardTypeChange,
  paymentProcessor,
  handlePaymentProcessorChange,
  transactionType,
  handleTransactionTypeChange,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
  pageSize,
  handlePerPage,
  setLoadings,
  handleResetFilter,
}) => {
  // redux nodes
  const { nodesData } = useSelector((state) => state.transactions);

  // close modal
  const handleCloseModal = () => {
    setFilterTransactionsModal(false);
  };

  // multi select animated components
  const animatedComponents = makeAnimated();

  // should render fields?
  const shouldRenderApprovedAmountField = entitySelected.some(
    (option) => option.value === "approved_amount"
  );

  const shouldRenderCampaignTagField = entitySelected.some(
    (option) => option.value === "campaign_tag"
  );

  const shouldRenderNodeTypeField = entitySelected.some(
    (option) => option.value === "node_type"
  );

  const shouldRenderTransactionTypeField = entitySelected.some(
    (option) => option.value === "transaction_type"
  );

  const shouldRenderNodeNameField = entitySelected.some(
    (option) => option.value === "node_name"
  );

  const shouldRenderCardTypeField = entitySelected.some(
    (option) => option.value === "card_type"
  );

  const shouldRenderPaymentProcessorField = entitySelected.some(
    (option) => option.value === "payment_processor"
  );

  const shouldRenderCardAccountField = entitySelected.some(
    (option) => option.value === "card_account"
  );

  const shouldRenderApprovalCodeField = entitySelected.some(
    (option) => option.value === "approval_code"
  );

  const shouldRenderEmailField = entitySelected.some(
    (option) => option.value === "email"
  );

  const shouldRenderInvoiceField = entitySelected.some(
    (option) => option.value === "invoice"
  );

  const shouldRenderFromDateField = entitySelected.some(
    (option) => option.value === "from_date"
  );

  const shouldRenderToDateField = entitySelected.some(
    (option) => option.value === "to_date"
  );

  const shouldRenderIssueTaxReceiptField = entitySelected.some(
    (option) => option.value === "issue_tax_receipt"
  );

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // from date change
  const handleFromDateChange = (value) => {
    if (value) {
      setFromDate(value);
    } else {
      setFromDate(null);
    }
  };

  // to date change
  const handleToDateChange = (value) => {
    if (value) {
      setToDate(value);
    } else {
      setToDate(null);
    }
  };

  // form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Prepare the data to be sent as API parameters based on the selected options
    const apiData = {};

    // Add 'approved amount' to the apiData if it's selected
    if (shouldRenderApprovedAmountField) {
      apiData.approved_amount = parseFloat(approvedAmount);
    }

    // Add 'card_account' to the apiData if it's selected
    if (shouldRenderCardAccountField) {
      apiData.card_account = cardAccount;
    }

    // Add 'approval_code' to the apiData if it's selected
    if (shouldRenderApprovalCodeField) {
      apiData.approval_code = approvalCode;
    }

    // Add 'invoice' to the apiData if it's selected
    if (shouldRenderInvoiceField) {
      apiData.invoice = invoice;
    }

    // Add 'email' to the apiData if it's selected
    if (shouldRenderEmailField) {
      apiData.email = email;
    }

    // Add 'issue_tax_receipt' to the apiData if it's selected
    if (shouldRenderIssueTaxReceiptField) {
      apiData.issue_tax_receipt = taxReceipt;
    }

    // Add 'campaign_tag' to the apiData if it's selected
    if (shouldRenderCampaignTagField) {
      apiData.campaign_tag = campaignTag;
    }

    // from date
    if (shouldRenderFromDateField && fromDate) {
      const formattedFromDate = formatDate(fromDate);
      apiData.from_date = formattedFromDate;
    }

    // to date
    if (shouldRenderToDateField && toDate) {
      const formattedToDate = formatDate(toDate);
      apiData.to_date = formattedToDate;
    }

    // node_type
    if (shouldRenderNodeTypeField) {
      apiData.node_type = nodeType;
    }

    // transaction_type
    if (shouldRenderTransactionTypeField) {
      apiData.transaction_type = transactionType;
    }

    // node_name
    if (shouldRenderNodeNameField) {
      apiData.node_name = nodeName;
    }

    // card_type
    if (shouldRenderCardTypeField) {
      apiData.card_type = cardType;
    }

    // payment_processor
    if (shouldRenderPaymentProcessorField) {
      apiData.payment_processor = paymentProcessor;
    }

    // sort by
    if (sortBy) {
      apiData.by = sortBy.value;
    }

    if (shouldRenderApprovedAmountField && approvedAmount > 0) {
      setLoadings(true);
      setFilterTransactionsModal(false);
      setFilteredParams({
        apiData,
        pageSize,
        order: orderBy.value,
      });
    } else if (!shouldRenderApprovedAmountField) {
      setLoadings(true);
      setFilterTransactionsModal(false);
      setFilteredParams({
        apiData,
        pageSize,
        order: orderBy.value,
      });
    }
  };

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
      const hours = String(dateObj.getHours()).padStart(2, "0");
      const minutes = String(dateObj.getMinutes()).padStart(2, "0");
      const formatted = `${year}-${month}-${day} ${hours}:${minutes}`;
      return formatted;
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Filter Transactions</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <h6
              style={{
                borderBottom: 1 + "px solid #eaeaea",
                paddingBottom: 5 + "px",
              }}
            >
              Select Fields
            </h6>
            {entityOptions && (
              <>
                <Select
                  isMulti
                  components={animatedComponents}
                  styles={styles}
                  options={entityOptions}
                  onChange={handleSelectChange}
                  value={entitySelected}
                />
                <form
                  style={{ marginTop: 10 + "px" }}
                  className="update-media-form"
                  onSubmit={handleFormSubmit}
                >
                  {/* approved amount */}
                  {shouldRenderApprovedAmountField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "approved_amount"
                          )?.label
                        }
                      </label>
                      <CustomTextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="small"
                        value={approvedAmount}
                        onChange={(e) => handleApprovedAmountChange(e)}
                        required
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "approved_amount"
                          )?.label
                        }
                        helperText={
                          approvedAmount !== "" && approvedAmount <= 0
                            ? "Value must be greater than 0"
                            : ""
                        }
                        error={approvedAmount !== "" && approvedAmount <= 0}
                      />
                    </div>
                  )}
                  {/* approval code */}
                  {shouldRenderApprovalCodeField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "approval_code"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "approval_code"
                          )?.label
                        }
                        required
                        value={approvalCode}
                        onChange={(e) => setApprovalCode(e.target.value)}
                      />
                    </div>
                  )}
                  {/* campaign tag */}
                  {shouldRenderCampaignTagField && nodesData && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "campaign_tag"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {nodesData.campaign_tag.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={campaignTag.includes(item.value)}
                              onChange={() =>
                                handleCampaignTagChange(item.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* card account */}
                  {shouldRenderCardAccountField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "card_account"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "card_account"
                          )?.label
                        }
                        required
                        value={cardAccount}
                        onChange={(e) => setCardAccount(e.target.value)}
                      />
                    </div>
                  )}
                  {/* email */}
                  {shouldRenderEmailField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "email"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "email"
                          )?.label
                        }
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  )}
                  {/* invoice */}
                  {shouldRenderInvoiceField && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "invoice"
                          )?.label
                        }
                      </label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder={
                          entitySelected.find(
                            (option) => option.value === "invoice"
                          )?.label
                        }
                        required
                        value={invoice}
                        onChange={(e) => setInvoice(e.target.value)}
                      />
                    </div>
                  )}
                  {/* node type */}
                  {shouldRenderNodeTypeField && nodesData && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "node_type"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {nodesData.node_type.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={nodeType.includes(item.value)}
                              onChange={() => handleNodeTypeChange(item.value)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* transaction type */}
                  {shouldRenderTransactionTypeField && nodesData && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "transaction_type"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {nodesData.transaction_type.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={transactionType.includes(item.value)}
                              onChange={() => handleTransactionTypeChange(item.value)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* node names */}
                  {shouldRenderNodeNameField && nodesData && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "node_name"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {nodesData.node_name.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={nodeName.includes(item.value)}
                              onChange={() => handleNodeNameChange(item.value)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* payment processor */}
                  {shouldRenderPaymentProcessorField && nodesData && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "payment_processor"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {nodesData.payment_processor.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={paymentProcessor.includes(item.value)}
                              onChange={() =>
                                handlePaymentProcessorChange(item.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* tender type */}
                  {shouldRenderCardTypeField && nodesData && (
                    <div className="input-margins-div">
                      <label>
                        {
                          entitySelected.find(
                            (option) => option.value === "card_type"
                          )?.label
                        }
                      </label>
                      <div className="checkbox-container">
                        {nodesData.card_type.map((item) => (
                          <div className="checkbox-div" key={item.value}>
                            <MDBCheckbox
                              label={item.label}
                              checked={cardType.includes(item.value)}
                              onChange={() => handleCardTypeChange(item.value)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* from date */}
                  {shouldRenderFromDateField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "from_date"
                          )?.label
                        }
                      </h6>
                      <DatePicker
                        style={{ width: 100 + "%" }}
                        className="edit-media-inputs"
                        selected={fromDate}
                        onChange={handleFromDateChange}
                        showTimeSelect
                        timeIntervals={5}
                        timeFormat="HH:mm"
                        dateFormat="yyyy-MM-dd HH:mm"
                        timeCaption="Time"
                        placeholderText={
                          entitySelected.find(
                            (option) => option.value === "from_date"
                          )?.label
                        }
                        isClearable
                        required
                      />
                    </div>
                  )}
                  {/* to date */}
                  {shouldRenderToDateField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "to_date"
                          )?.label
                        }
                      </h6>
                      <DatePicker
                        style={{ width: 100 + "%" }}
                        className="edit-media-inputs"
                        selected={toDate}
                        onChange={handleToDateChange}
                        showTimeSelect
                        timeIntervals={5}
                        timeFormat="HH:mm"
                        dateFormat="yyyy-MM-dd HH:mm"
                        timeCaption="Time"
                        placeholderText={
                          entitySelected.find(
                            (option) => option.value === "to_date"
                          )?.label
                        }
                        isClearable
                        required
                      />
                    </div>
                  )}
                  {/* tax receipt */}
                  {shouldRenderIssueTaxReceiptField && (
                    <div className="input-margins-div">
                      <h6>
                        {
                          entitySelected.find(
                            (option) => option.value === "issue_tax_receipt"
                          )?.label
                        }
                      </h6>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={taxReceipt === 1}
                          onChange={() =>
                            setTaxReceipt(taxReceipt === 0 ? 1 : 0)
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  )}
                  {/* page size */}
                  <div className="input-margins-div">
                    <label>Records per page</label>
                    <Select
                      value={perPageOptions.find(
                        (option) => option.value === pageSize
                      )}
                      onChange={(option) => handlePerPage(option.value)}
                      options={perPageOptions}
                      styles={styles}
                    />
                  </div>
                  {/* sort by */}
                  <div className="input-margins-div">
                    <label>Sort by</label>
                    <Select
                      value={sortBy}
                      onChange={(option) => setSortBy(option)}
                      options={entityOptions}
                      styles={styles}
                      isClearable
                    />
                  </div>
                  {/* order by */}
                  <div className="input-margins-div">
                    <label>Order by</label>
                    <Select
                      value={orderBy}
                      onChange={(option) => setOrderBy(option)}
                      options={[
                        { value: "asc", label: "Ascending" },
                        { value: "desc", label: "Descending" },
                      ]}
                      styles={styles}
                    />
                  </div>
                  <div className="reset-and-filter-div">
                    <button
                      type="button"
                      className="reset-fields-btn"
                      onClick={handleResetFilter}
                    >
                      RESET
                    </button>
                    <button type="submit" className="filter-records-btn">
                      FILTER
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FilterTransactionsModal;
