/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { userPlus } from "react-icons-kit/feather/userPlus";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addNewReceipt,
  clearPrevReceiptData,
} from "../../Store/Slices/Sidebar/TaxReceipts/TaxReceiptsSlice";
import { getDonorsByEmail } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";
import Tippy from "@tippyjs/react";
import AddDonorModal from "../Donors/AddDonorModal";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const NewReceiptPopup = ({
  token,
  setNewReceiptPopup,
  yearOptions,
  countries,
  usaStates,
  provinces,
  donorSpecific,
  donorSpecificEmail,
}) => {
  // redux state
  const { newReceiptLoading, newReceiptData } = useSelector(
    (state) => state.taxReceipts
  );

  // close modal
  const handleCloseModal = () => {
    if (!newReceiptLoading) {
      setNewReceiptPopup(false);
    }
  };

  // states
  const [addDonorStep, setAddDonorStep] = useState(false);
  const [receiptEmail, setReceiptEmail] = useState("");

  useEffect(() => {
    if (donorSpecific !== undefined) {
      setReceiptEmail(donorSpecificEmail);
    } else {
      setReceiptEmail("");
    }
  }, [donorSpecific]);

  // timer state
  const [timer, setTimer] = useState(null);

  // suggestions state
  const [suggestions, setSuggestions] = useState([]);

  // handle receipt email change event
  const handleReceiptEmail = (value) => {
    setReceiptEmail(value);
    // clear any existing timer
    if (timer) {
      clearTimeout(timer);
    }
    // create a new timer of 1 second
    const newTimer = setTimeout(() => {
      if (value.length > 3) {
        dispatch(
          getDonorsByEmail({
            token,
            email: value,
          })
        ).then((res) => {
          if (res.payload && res.payload.data && res.payload.data.length > 0) {
            setSuggestions(res.payload.data);
          } else if (
            res.payload &&
            res.payload.data &&
            res.payload.data.length < 1
          ) {
            setSuggestions([]);
          } else if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
      } else {
        setSuggestions([]);
      }
    }, 1000);
    setTimer(newTimer);
  };
  const [year, setYear] = useState((new Date().getFullYear() - 1).toString()); // current year - 1
  const [prefix, setPrefix] = useState("");

  // handle year change
  const handleYearChange = (value) => {
    if (year !== value) {
      setYear(value);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      email: receiptEmail,
      year,
      prefix,
    };
    dispatch(addNewReceipt(singleObj)).then((res) => {
      if (!res.payload.axiosError) {
        setNewReceiptPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  // clear previously added new receipt data, it will clear error on opening popup
  useEffect(() => {
    dispatch(clearPrevReceiptData());
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className={`update-widgets-modal ${
            !addDonorStep ? "" : "media extra-width"
          }`}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          {!addDonorStep ? (
            <>
              <div className="update-widgets-modal-header">
                <div>New Receipt</div>
                <div
                  className="cancel-widget-update-icon"
                  onClick={handleCloseModal}
                >
                  <Icon icon={x} size={16} />
                </div>
              </div>
              <div className="update-widgets-modal-body">
                <form className="update-media-form" onSubmit={handleSubmit}>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Email</label>
                      <div className="email-input-container">
                        <input
                          type="email"
                          className="edit-media-inputs no-borders-right"
                          placeholder="Email"
                          value={receiptEmail}
                          onChange={(e) => handleReceiptEmail(e.target.value)}
                          required
                          readOnly={donorSpecific !== undefined}
                        />

                        {/* suggestion popup */}
                        {suggestions.length > 0 && (
                          <div className="suggestions-popup">
                            {suggestions.map((suggestion, index) => (
                              <div
                                key={index}
                                className="suggestion-item"
                                onClick={() => {
                                  setReceiptEmail(suggestion.email);
                                  setSuggestions([]);
                                }}
                              >
                                <div className="suggestion-email">
                                  {suggestion.email}
                                </div>
                                <div className="suggestion-name">
                                  {suggestion.firstName} {suggestion.lastName}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {donorSpecific === undefined && (
                          <Tippy content="Add Donor" zIndex={100001}>
                            <button
                              type="button"
                              className="add-button"
                              onClick={() => setAddDonorStep(true)}
                            >
                              <Icon icon={userPlus} size={20} />
                            </button>
                          </Tippy>
                        )}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Year</label>
                      <Select
                        value={yearOptions.find(
                          (option) => option.value === year
                        )}
                        onChange={(option) => {
                          handleYearChange(option ? option.value : null);
                        }}
                        options={yearOptions}
                        styles={styles}
                        menuPosition="fixed"
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mb-3">
                    <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                      <label>Prefix</label>
                      <input
                        type="text"
                        className="edit-media-inputs"
                        placeholder="Prefix"
                        value={prefix}
                        onChange={(e) => setPrefix(e.target.value)}
                        maxLength={10}
                      />
                    </MDBCol>
                  </MDBRow>
                  {newReceiptData && newReceiptData.apiError ? (
                    <div className="error-msg">{newReceiptData.apiError}</div>
                  ) : (
                    <>
                      {newReceiptData && newReceiptData.axiosError && (
                        <div className="error-msg">
                          {newReceiptData.axiosError}
                        </div>
                      )}
                    </>
                  )}
                  <div className="submit-and-cancel-div">
                    <button
                      type="button"
                      className="cancel"
                      onClick={handleCloseModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="submit addModal"
                      disabled={newReceiptLoading}
                    >
                      {newReceiptLoading ? (
                        <MetroSpinner
                          size={22}
                          color="#fff"
                          loading={newReceiptLoading}
                        />
                      ) : (
                        "ADD"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <AddDonorModal
              token={token}
              setNewReceiptPopup={setNewReceiptPopup}
              countries={countries}
              usaStates={usaStates}
              provinces={provinces}
              addDonorStep={addDonorStep}
              setAddDonorStep={setAddDonorStep}
              setTransactionEmail={setReceiptEmail}
              setSuggestions={setSuggestions}
            />
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NewReceiptPopup;
