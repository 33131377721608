import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateNotification } from "../../Store/Slices/Sidebar/Notifications/NotificationsSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const EditNotificationPopup = ({
  token,
  setEditNotificationPopup,
  editNotificationObj,
  stateOptions,
}) => {
  // redux state
  const { updateNotificationLoading, updateNotificationData } = useSelector(
    (state) => state.notifications
  );

  // close modal
  const handleCloseModal = () => {
    if (!updateNotificationLoading) {
      setEditNotificationPopup(false);
    }
  };

  // form states
  const [notes, setNotes] = useState(editNotificationObj.notes);
  const [state, setState] = useState({
    value: "",
    label: "",
  });

  // update state
  useEffect(() => {
    const currentState =
      stateOptions &&
      stateOptions.find((obj) => obj.label === editNotificationObj.state);
    setState(currentState);
  }, [stateOptions, editNotificationObj.state]);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // update notification
  const handleUpdateNotification = (e) => {
    e.preventDefault();
    const singleObj = {
      token,
      tagNumber: editNotificationObj.tagNumber,
      state: state.value,
      notes,
    };
    dispatch(updateNotification(singleObj)).then((res) => {
      if (!res.payload.axiosError) {
        setEditNotificationPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Edit Notification - {editNotificationObj.tagNumber}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form
              className="update-media-form"
              onSubmit={handleUpdateNotification}
            >
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>State</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="bottom"
                    value={stateOptions.find(
                      (option) => option.value === state.value
                    )}
                    onChange={(option) => setState(option)}
                    options={stateOptions}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Notes</label>
                  <textarea
                    className="edit-media-inputs text-area"
                    value={notes || ""}
                    onChange={(e) => setNotes(e.target.value)}
                    maxLength={500}
                  />
                </MDBCol>
              </MDBRow>

              {updateNotificationData &&
              updateNotificationData.apiError &&
              updateNotificationData.tagNumber ===
                editNotificationObj.tagNumber ? (
                <div className="error-msg">
                  {updateNotificationData.apiError}
                </div>
              ) : (
                <>
                  {updateNotificationData &&
                    updateNotificationData.axiosError &&
                    updateNotificationData.tagNumber ===
                      editNotificationObj.tagNumber && (
                      <div className="error-msg">
                        {updateNotificationData.axiosError}
                      </div>
                    )}
                </>
              )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={updateNotificationLoading}
                >
                  {updateNotificationLoading ? (
                    <MetroSpinner
                      loading={updateNotificationLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EditNotificationPopup;
