import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { info } from "react-icons-kit/feather/info";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addETransferConfiguration } from "../../Store/Slices/Sidebar/ETransfer/ETransferSlice";
import toast from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import Swal from "sweetalert2";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "180px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const AddNewETransferConfigurationModal = ({
  token,
  setAddNewModal,
  campaignOptions,
  eTransferTypeOptions,
  encryptionOptions,
  protocolOptions,
}) => {
  // redux state
  const { addETransferLoading } = useSelector((state) => state.eTransfer);
  // steps
  // general
  // authentication
  // mail-server (optional) when eTransferType is IMAP
  const [step, setStep] = useState("general");

  // close modal
  const handleCloseModal = () => {
    if (!addETransferLoading) {
      setAddNewModal(false);
    }
  };

  // general states
  // campaign
  const [selectedCampaign, setSelectedCampaign] = useState({
    value: "",
    label: "",
    minimumAmount: "",
    fees: "",
  });

  // start date
  let initialStartDate = new Date("2024-01-01T00:00:00");
  initialStartDate.setHours(0, 0, 0, 0); // Set the time to midnight
  const [startDate, setStartDate] = useState(initialStartDate);

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  // hiding the past dates
  const isSelectableDate = (date) => {
    return date >= initialStartDate;
  };

  // alert email
  const [alertEmail, setAlertEmail] = useState("");

  // archive folder
  const [archiveFolder, setArchiveFolder] = useState("eTransfer");

  // status
  const [status, setStatus] = useState(true);

  // status change
  const handleStatusChange = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  // general to auth next step
  const handleNextStep = (e) => {
    e.preventDefault();
    setStep("authentication");
  };

  // authentication
  // userName
  const [userName, setUserName] = useState("");

  // password
  const [password, setPassword] = useState("");

  // email alias
  const [emailAlias, setEmailAlias] = useState("");

  // etransfer type
  const [selectedTransferType, setSelectedTransferType] = useState("IMAP");

  // handle next step from authentication
  const handleNextStepFromAuthentication = (e) => {
    e.preventDefault();
    if (selectedTransferType === "IMAP") {
      setStep("mail-server");
    } else {
      dispatchAction();
    }
  };

  // mail server
  const [host, setHost] = useState("");
  const [port, setPort] = useState(993);
  const [encryption, setEncryption] = useState("SSL");
  const [validateCert, setValidateCert] = useState(true);
  // cert change
  const handleValidateCert = () => {
    setValidateCert((prevStatus) => !prevStatus);
  };
  const [protocol, setProtocol] = useState("IMAP");

  // handle submit from mail server
  const handleSubmitFromMailServer = (e) => {
    e.preventDefault();
    dispatchAction();
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // dispatch action
  const dispatchAction = () => {
    const singleObj = {
      token,
      campaignTag: selectedCampaign.value,
      startDate: formatDate(startDate),
      alertEmail,
      archiveFolder,
      status,
      username: userName,
      password,
      emailAlias,
      selectedTransferType,
      host,
      port,
      encryption,
      validateCert,
      protocol,
    };
    dispatch(addETransferConfiguration(singleObj)).then((res) => {
      if (!res.payload.axiosError) {
        setAddNewModal(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      } else if (res.payload.apiError) {
        Swal.fire({
          title: "Error!",
          text: res.payload.apiError,
          icon: "error",
          customClass: {
            container: "custom-sweetalert-container",
            confirmButton: "custom-ok-button",
          },
          didOpen: () => {
            // Set the zIndex for the SweetAlert container
            document.querySelector(
              ".custom-sweetalert-container"
            ).style.zIndex = 999999;
          },
          confirmButtonText: "OK",
        });
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              New eTransfer Configuration - {step === "general" && "General"}
              {step === "authentication" && "Authentication"}
              {step === "mail-server" && "Mail Server"}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          {step === "general" && (
            <div className="update-widgets-modal-body">
              <form
                className="update-media-form"
                onSubmit={handleNextStep}
                autoComplete="off"
              >
                {/* Campaign */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="campaign">
                      Campaign
                      <Tippy
                        content="Campaign designated for receiving funds."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="bottom"
                      value={campaignOptions.find(
                        (option) => option.value === selectedCampaign.value
                      )}
                      onChange={(option) => setSelectedCampaign(option)}
                      options={campaignOptions}
                    />
                  </MDBCol>
                </MDBRow>
                {/* start date */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-mb-0">
                    <label htmlFor="startDate">
                      Start Date
                      <Tippy
                        content="Initial date to begin receiving eTransfer funds into the portal."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <ReactDatePicker
                      style={{
                        width: 100 + "%",
                      }}
                      className="edit-media-inputs"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Start Date"
                      required
                      minDate={new Date("2024-01-01T00:00:00")}
                      filterDate={isSelectableDate}
                    />
                  </MDBCol>
                </MDBRow>
                {/* alert email */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="alertEmail">
                      Alert Email
                      <Tippy
                        content="Enter an email address to receive eTransfer details. If you do not wish to receive alerts, you can leave this field blank."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="email"
                      className="edit-media-inputs"
                      placeholder="Alert Email"
                      value={alertEmail}
                      onChange={(e) => setAlertEmail(e.target.value)}
                      maxLength={255}
                      autoComplete="new-email"
                    />
                  </MDBCol>
                </MDBRow>
                {/* archive folder */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="archiveFolder">
                      Archive Folder
                      <Tippy
                        content="Specify the folder name where emails should be relocated after import into the portal. Leave blank to retain emails in the inbox."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      placeholder="Archive Folder"
                      value={archiveFolder}
                      onChange={(e) => setArchiveFolder(e.target.value)}
                      maxLength={20}
                    />
                  </MDBCol>
                </MDBRow>
                {/* status */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <h6>
                      Status
                      <Tippy
                        content="Activate this configuration to begin importing eTransfer data from the specified start date, or create the configuration without activating it."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </h6>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={status}
                        onChange={handleStatusChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </MDBCol>
                </MDBRow>
                {/* cancel and next button */}
                <div className="submit-and-cancel-div">
                  <button
                    type="button"
                    className="cancel"
                    onClick={handleCloseModal}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="submit addModal">
                    NEXT
                  </button>
                </div>
              </form>
            </div>
          )}
          {step === "authentication" && (
            <div className="update-widgets-modal-body">
              <form
                className="update-media-form"
                onSubmit={handleNextStepFromAuthentication}
                autoComplete="off"
              >
                {/* eTransfer Type */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="emailServiceProvider">
                      Email Service Provider
                      <Tippy
                        content="Select an email service provider"
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="bottom"
                      value={eTransferTypeOptions.find(
                        (option) => option.value === selectedTransferType
                      )}
                      onChange={(option) =>
                        setSelectedTransferType(option.value)
                      }
                      options={eTransferTypeOptions}
                    />
                  </MDBCol>
                </MDBRow>
                {/* username */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="username">
                      Username
                      <Tippy
                        content="Enter the email address where you receive notifications for eTransfer payments received from Interac."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="email"
                      className="edit-media-inputs"
                      placeholder="Username"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      maxLength={50}
                      required
                      autoComplete="new-username"
                    />
                  </MDBCol>
                </MDBRow>
                {/* password */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="password">
                      Password
                      <Tippy
                        content="Enter the password for the email address specified above."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="password"
                      className="edit-media-inputs"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete="new-password"
                    />
                  </MDBCol>
                </MDBRow>
                {/* email alias */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="emailAlias">
                      Email Alias
                      <Tippy
                        content="An alternate email address that forwards to your primary inbox."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="email"
                      className="edit-media-inputs"
                      placeholder="Email Alias"
                      value={emailAlias}
                      onChange={(e) => setEmailAlias(e.target.value)}
                      maxLength={50}
                      autoComplete="new-email-alias"
                    />
                  </MDBCol>
                </MDBRow>
                {/* back and next/submit button */}
                <div className="submit-and-cancel-div">
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => setStep("general")}
                    disabled={addETransferLoading}
                  >
                    BACK
                  </button>
                  <button type="submit" className="submit addModal">
                    {selectedTransferType === "IMAP" ? (
                      "NEXT"
                    ) : (
                      <>
                        {addETransferLoading ? (
                          <MetroSpinner
                            loading={addETransferLoading}
                            color="#fff"
                            size={22}
                          />
                        ) : (
                          "SUBMIT"
                        )}
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          )}
          {step === "mail-server" && (
            <div className="update-widgets-modal-body">
              <form
                className="update-media-form"
                onSubmit={handleSubmitFromMailServer}
                autoComplete="off"
              >
                {/* host */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="host">
                      Host
                      <Tippy
                        content="Enter the mail server's host name."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      placeholder="Host"
                      value={host}
                      onChange={(e) => setHost(e.target.value)}
                      maxLength={255}
                      required
                    />
                  </MDBCol>
                </MDBRow>
                {/* port */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="port">
                      Port
                      <Tippy
                        content="Enter the port number for the mail server."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      placeholder="Port"
                      value={port}
                      onChange={(e) => {
                        const numericInput = e.target.value.replace(/\D/, "");
                        setPort(numericInput);
                      }}
                      required
                    />
                  </MDBCol>
                </MDBRow>
                {/* encryption */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="encryption">
                      Encryption
                      <Tippy
                        content="Select the encryption method used by the mail server."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="bottom"
                      value={encryptionOptions.find(
                        (option) => option.value === encryption
                      )}
                      onChange={(option) => setEncryption(option.value)}
                      options={encryptionOptions}
                    />
                  </MDBCol>
                </MDBRow>
                {/* validate cert */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <h6>
                      Validate Certificate
                      <Tippy
                        content="verify the mail server's SSL certificate."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </h6>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={validateCert}
                        onChange={handleValidateCert}
                      />
                      <span className="slider round"></span>
                    </label>
                  </MDBCol>
                </MDBRow>
                {/* protocol */}
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label htmlFor="protocol">
                      Protocol
                      <Tippy
                        content="Choose the protocol to be used with the mail server."
                        zIndex={1000001}
                      >
                        <span style={{ marginLeft: "5px", color: "#6a6c6f" }}>
                          <Icon icon={info} size={18} />
                        </span>
                      </Tippy>
                    </label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="top"
                      value={protocolOptions.find(
                        (option) => option.value === protocol
                      )}
                      onChange={(option) => setProtocol(option.value)}
                      options={protocolOptions}
                    />
                  </MDBCol>
                </MDBRow>
                {/* back and submit button */}
                <div className="submit-and-cancel-div">
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => setStep("authentication")}
                    disabled={addETransferLoading}
                  >
                    BACK
                  </button>
                  <button type="submit" className="submit addModal">
                    {addETransferLoading ? (
                      <MetroSpinner
                        loading={addETransferLoading}
                        color="#fff"
                        size={22}
                      />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
              </form>
            </div>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddNewETransferConfigurationModal;
