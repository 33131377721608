import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { switchToHold } from "../../Store/Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const SwitchToHoldPopup = ({
  token,
  setSwitchToHoldPopup,
  switchToHoldObj,
}) => {
  // redux state
  const { switchToHoldLoading, switchToHoldData } = useSelector(
    (state) => state.recurringPayments
  );

  // close modal
  const handleCloseModal = () => {
    if (!switchToHoldLoading) {
      setSwitchToHoldPopup(false);
    }
  };

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // switch to hold
  const handleSwitchToHold = () => {
    dispatch(
      switchToHold({
        token,
        recurringTag: switchToHoldObj.tagNumber,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        setSwitchToHoldPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Switch To Hold</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#DC3545" }}>
                Are you sure you want to hold this recurring?
              </p>
            </div>

            {switchToHoldData &&
            switchToHoldData.apiError &&
            switchToHoldData.recurringTag === switchToHoldObj.tagNumber ? (
              <div className="error-msg">{switchToHoldData.apiError}</div>
            ) : (
              <>
                {switchToHoldData &&
                  switchToHoldData.axiosError &&
                  switchToHoldData.recurringTag ===
                    switchToHoldObj.tagNumber && (
                    <div className="error-msg">
                      {switchToHoldData.axiosError}
                    </div>
                  )}
              </>
            )}

            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                className="submit delete"
                disabled={switchToHoldLoading}
                onClick={handleSwitchToHold}
              >
                {switchToHoldLoading ? (
                  <MetroSpinner
                    loading={switchToHoldLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  "HOLD"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default SwitchToHoldPopup;
