import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { MetroSpinner } from "react-spinners-kit";
import { useNavigate } from "react-router-dom";
import { changeEmail } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { toast } from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const EmailChangePopup = ({ setEmailChangePopup, transaction, token }) => {
  // redux state
  const { changeEmailLoading, changeEmailData } = useSelector(
    (state) => state.transactions
  );

  // state
  const [email, setEmail] = useState(transaction.email);

  // close modal
  const handleCloseModal = () => {
    if (!changeEmailLoading) {
      setEmailChangePopup(false);
    }
  };

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // email change event
  const handleEmailChange = (e) => {
    e.preventDefault();
    dispatch(
      changeEmail({
        token,
        tagNumber: transaction.transactionTag,
        email,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        setEmailChangePopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Update Email</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleEmailChange}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Email</label>
                  <input
                    type="email"
                    className="edit-media-inputs"
                    value={email || ""}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </MDBCol>
              </MDBRow>

              {changeEmailData &&
                changeEmailData.tagNumber === transaction.transactionTag &&
                (changeEmailData.apiError || changeEmailData.axiosError) && (
                  <div className="error-msg">
                    {changeEmailData.apiError || changeEmailData.axiosError}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={changeEmailLoading}
                >
                  {changeEmailLoading ? (
                    <MetroSpinner
                      loading={changeEmailLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EmailChangePopup;
