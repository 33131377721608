import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import hostName from "../../../../config";
import axios from "axios";

// Create an async thunk get all campaign
export const getCampaign = createAsyncThunk(
  "CAMPAIGN/GET",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/campaign?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// Create an async thunk get all campaign
export const filterCampaign = createAsyncThunk(
  "CAMPAIGN/FILTER",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "name",
      "description",
      "start_date",
      "end_date",
      "status",
      "issue_tax_receipt",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const request = await axios.get(
      `${hostName}api/admin/v1/campaign?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// Create an async thunk delete campaign
export const deleteCampaign = createAsyncThunk(
  "CAMPAIGN/DELETE",
  async ({ token, id }) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/campaign/${id}`,
        axiosConfig
      );
      const response = await request.data;
      return { response, id };
    } catch (err) {
      throw err.response.data.messageDetails[0];
    }
  }
);

// Create an async thunk update campaign
export const updateCampaign = createAsyncThunk(
  "CAMPAIGN/UPDATE",
  async ({ id, token, obj }) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/campaign/${id}`,
        obj,
        axiosConfig
      );
      const response = await request.data;
      return { response, id };
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.messageDetails
      ) {
        const messageDetails = err.response.data.messageDetails;
        const fieldWithError = Object.keys(messageDetails)[0];
        const errorMessage = messageDetails[fieldWithError][0];
        throw errorMessage;
      } else {
        throw err.response.data.message || err.message;
      }
    }
  }
);

// create an async thunk get list of value by filter
export const listOfValues = createAsyncThunk(
  "CAMPAIGN/LISTOFVALUES",
  async ({ token, listName }) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/lov?listName=${listName}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

// Create an async thunk add campaign
export const addCampaign = createAsyncThunk(
  "CAMPAIGN/CREATE",
  async ({ token, obj }) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/campaign`,
        obj,
        axiosConfig
      );
      const response = await request.data;
      return response;
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.messageDetails
      ) {
        const messageDetails = err.response.data.messageDetails;
        const fieldWithError = Object.keys(messageDetails)[0];
        const errorMessage = messageDetails[fieldWithError][0];
        throw errorMessage;
      } else {
        throw err.response.data.message || err.message;
      }
    }
  }
);

export const CampaignSlice = createSlice({
  name: "CAMPAIGN",
  initialState: {
    // get data
    loader: false,
    campaignData: null,
    error: null,

    // delete data
    deleteLoader: false,
    deleteSuccess: null,
    deleteError: null,

    // update data
    updateLoader: false,
    updateSuccessMessage: null,
    updateError: null,

    // listOfValues
    listOfValuesLoader: false,
    listOfValuesData: [],
    listOfValuesErr: null,

    // create data
    createLoader: false,
    createSuccessMessage: null,
    createError: null,
  },
  reducers: {
    deleteModalMount: (state) => {
      state.deleteLoader = false;
      state.deleteSuccess = null;
      state.deleteError = null;
    },
    updateModalMount: (state) => {
      state.updateLoader = false;
      state.updateSuccessMessage = null;
      state.updateError = null;
    },
    createModalMount: (state) => {
      state.createLoader = false;
      state.createSuccessMessage = null;
      state.createError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getCampaign.pending, (state) => {
        state.loader = true;
        state.campaignData = null;
        state.error = null;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.loader = false;
        state.campaignData = action.payload;
        state.error = null;
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.loader = false;
        state.campaignData = null;
        state.error = action.error.message;
      })

      // filter
      .addCase(filterCampaign.pending, (state) => {
        state.loader = true;
        state.campaignData = null;
        state.error = null;
      })
      .addCase(filterCampaign.fulfilled, (state, action) => {
        state.loader = false;
        state.campaignData = action.payload;
        state.error = null;
      })
      .addCase(filterCampaign.rejected, (state, action) => {
        state.loader = false;
        state.campaignData = null;
        state.error = action.error.message;
      })

      // delete
      .addCase(deleteCampaign.pending, (state) => {
        state.deleteLoader = true;
        state.deleteSuccess = null;
        state.deleteError = null;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.deleteLoader = false;
        state.deleteSuccess = action.payload.response.messageDetails[0];
        if (action.payload.response.result) {
          state.campaignData.data = state.campaignData.data.filter(
            (filtered) => filtered.tagNumber !== action.payload.id
          );
        }
        state.deleteError = null;
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.deleteLoader = false;
        state.deleteSuccess = null;
        state.deleteError = action.error.message;
      })

      // update
      .addCase(updateCampaign.pending, (state) => {
        state.updateLoader = true;
        state.updateSuccessMessage = null;
        state.updateError = null;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.updateLoader = false;
        state.updateSuccessMessage = action.payload.response.messageDetails[0];
        if (action.payload.response.result) {
          const updIndex = state.campaignData.data.findIndex(
            (item) => item.tagNumber === action.payload.id
          );
          if (updIndex !== -1) {
            state.campaignData.data[updIndex] = action.payload.response.data;
          }
        }
        state.updateError = null;
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.updateLoader = false;
        state.updateSuccessMessage = null;
        state.updateError = action.error.message;
      })

      // listOfValues
      .addCase(listOfValues.pending, (state) => {
        state.listOfValuesLoader = true;
        state.listOfValuesData = [];
        state.listOfValuesErr = null;
      })
      .addCase(listOfValues.fulfilled, (state, action) => {
        state.listOfValuesLoader = false;
        state.listOfValuesData = action.payload;
        state.listOfValuesErr = null;
      })
      .addCase(listOfValues.rejected, (state, action) => {
        state.listOfValuesLoader = false;
        state.listOfValuesData = [];
        state.listOfValuesErr = action.error.message;
      })

      // create
      .addCase(addCampaign.pending, (state) => {
        state.createLoader = true;
        state.createSuccessMessage = null;
        state.createError = null;
      })
      .addCase(addCampaign.fulfilled, (state, action) => {
        state.createLoader = false;
        state.createSuccessMessage = action.payload.messageDetails[0];
        if (action.payload.result) {
          state.campaignData.data.unshift(action.payload.data);
        }
        state.createError = null;
      })
      .addCase(addCampaign.rejected, (state, action) => {
        state.createLoader = false;
        state.createSuccessMessage = null;
        state.createError = action.error.message;
      });
  },
});
export const { deleteModalMount, createModalMount, updateModalMount } =
  CampaignSlice.actions;
export default CampaignSlice.reducer;
