import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateCampaign } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import Select from "react-select";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "180px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const UpdateCampaignPopup = ({
  token,
  setUpdateCampaignPopup,
  updateCampaignObj,
  allCampaigns,
}) => {
  // redux state
  const { updateCampaignLoading, updateCampaignData } = useSelector(
    (state) => state.transactions
  );

  // close modal
  const handleCloseModal = () => {
    if (!updateCampaignLoading) {
      setUpdateCampaignPopup(false);
    }
  };

  // campaign
  const [selectedCampaign, setSelectedCampaign] = useState({
    value: updateCampaignObj.campaignTag,
    label: updateCampaignObj.campaign,
  });

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // form submit
  const handleCampaignChange = (e) => {
    e.preventDefault();
    dispatch(
      updateCampaign({
        token,
        tagNumber: updateCampaignObj.transactionTag,
        campaignTag: selectedCampaign.value,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        setUpdateCampaignPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Update Campaign</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleCampaignChange}>
              {/* Campaign */}
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>Campaign</label>
                  <Select
                    styles={styles}
                    required
                    menuPlacement="top"
                    menuPosition="fixed"
                    value={allCampaigns.find(
                      (option) => option.value === selectedCampaign.value
                    )}
                    onChange={(option) => setSelectedCampaign(option)}
                    options={allCampaigns}
                  />
                </MDBCol>
              </MDBRow>

              {updateCampaignData &&
                updateCampaignData.tagNumber === updateCampaignObj.transactionTag &&
                (updateCampaignData.apiError || updateCampaignData.axiosError) && (
                  <div className="error-msg">
                    {updateCampaignData.apiError || updateCampaignData.axiosError}
                  </div>
                )}
              
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  style={{
                    opacity:
                      updateCampaignLoading ||
                      updateCampaignObj.campaignTag === selectedCampaign.value
                        ? 0.5
                        : 1,
                    pointerEvents:
                      updateCampaignLoading ||
                      updateCampaignObj.campaignTag === selectedCampaign.value
                        ? "none"
                        : "auto",
                  }}
                >
                  {updateCampaignLoading ? (
                    <MetroSpinner
                      loading={updateCampaignLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UpdateCampaignPopup;
